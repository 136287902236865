// 第二层，分析规则和模式，生成 dataset，给 peg board，得到对阵表视图给 detail
import React, { useRef, useState } from 'react'
import { cn } from '@/shadcn/utils'
import { useBoolean, useFullscreen } from 'react-use'
import { PegBoard, useMatchesDataset } from './graph-blocks/peg-board'
import { MatchSmallCard } from './graph-blocks/match-small-card'
import QuicklyBar from './graph-blocks/quickly-bar-in-graph'
import { SwissBoard } from './graph-blocks/swiss-board'

const spacerX = (x) => x * 290
const spacerY = (y) => y * 100


const getMatchesContentSize = (matches) => {
  if (!matches) {
    return {
      width: 300,
      height: 300
    }
  }
  let mostX = Math.max(...matches.map(i => i.x))
  let mostY = Math.max(...matches.map(i => i.y))
  return {
    width: spacerX(mostX + 1) + 60,
    height: spacerY(mostY + 1) + 160 // 这 160 人工修正，为了操作栏空间
  }
}

export function MatchGraph({ event, mutate, matching, inScreenModal }) {
  const [, setHighLightMatchNumber] = useState(null)
  const board = useRef(null)
  const matches = event?.matchController?.matches
  let { width: contentWidth, height: contentHeight } = getMatchesContentSize(matches)
  // 全屏相关
  const graphRef = useRef(null)
  const [fs, toggleFullscreen] = useBoolean(false) // 占满显示器
  const screenFull = useFullscreen(graphRef, fs, { onClose: () => toggleFullscreen(false) })


  const reload = async () => {
    await mutate()
    await board.current?.reload()
  }
  let dataset = useMatchesDataset(event, matches, (node) => {
    // let number = i.tournamentProperties?.number
    // let flash = number === highLightMatchNumber && event?.stage === "3-INPROGRESS"
    let i = matches.find(m => m.id === node.key)
    return (
      <div key={i.id}
        data-match-id={i.id}
        className={cn(
          "absolute duration-1000 rounded-sm",
        )}
      >
        <MatchSmallCard
          stage={event?.stage}
          match={i}
          reload={reload}
        />
      </div>
    )
  })

  const isSwiss = event.ruleTemplateKey === "swiss-1"
  const isTournament = event.ruleTemplateKey === "tournament-1" || event.ruleTemplateKey === "tournament-2"


  return (
    <div ref={graphRef} className={cn({ "overflow-scroll": screenFull })}>
      <div className={cn(
        "w-full relative p-1",
        { "min-h-[calc(100dvh_-_6rem)]": screenFull || inScreenModal },
      )}>
        {matches && isSwiss && (
          <SwissBoard event={event} reload={reload} matching={matching} />
        )}
        {matches && dataset && isTournament && (
          <PegBoard
            ref={board}
            event={event}
            containerSize={{ w: contentWidth, h: contentHeight }}
            dataset={dataset}
            screenFull={screenFull}
            reload={reload}
          />
        )}
      </div>
      <QuicklyBar
        event={event}
        mutate={reload}
        feMutate={() => board.current?.reload()}
        screenFull={screenFull}
        toggleFullscreen={toggleFullscreen}
        inScreenModal={inScreenModal}
        setHighLightMatchNumber={setHighLightMatchNumber}
        matching={matching}
      />
    </div>
  )
}

