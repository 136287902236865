import * as z from "zod"
import { cn } from "@/shadcn/utils"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/shadcn/components/ui/form'
import { Button } from '@/shadcn/components/ui/button'
import { useForm } from 'react-hook-form'
import { zodResolver } from "@hookform/resolvers/zod"
import { Input } from '@/shadcn/components/ui/input'
import { Popover, PopoverContent, PopoverTrigger } from '@/shadcn/components/ui/popover'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from '@/shadcn/components/ui/command'
import { Check, ChevronDown, Minus, Plus } from "lucide-react"
import { post } from '@/utils/laf-db'
import { HeaderText } from '@/components/header-text'
import { RadioGroup, RadioGroupItem } from '@/shadcn/components/ui/radio-group'
import { Textarea } from '@/shadcn/components/ui/textarea'
import { useNavigate } from "react-router-dom"
import { EventCell } from "./event-page-list"
import { finder, useStaticData } from "@/utils/app-init"
import { PlayerSmallCard } from "@/components/player-card"
import { Modal } from "@/shadcn/components/_modal"
import { PosterPicker } from "@/components/poster-picker"
import { PlayersPicker } from "@/components/players-picker"
import { Switch } from "@/shadcn/components/ui/switch"
import { usePlayers } from "@/hooks/use-players"


const formSchema = z.object({
  name: z.string().min(2, { message: "Name must be at least 2 characters." }),
  description: z.string(),
  ruleTemplateKey: z.string(),
  gameKey: z.string({ required_error: "Select a game or sport", }),
  visibility: z.string(),
  poster: z.string(),
  // attendees: z.array(z.instanceof(Player)),
  attendeesIds: z.array(z.string()).optional(),
  registrationPage: z.boolean(),
})

export const CreateEventPage = ({ event, scene }) => {
  const navigate = useNavigate()
  let { rules, games } = useStaticData()
  let { data: allPlayers } = usePlayers()

  const form = useForm({
    resolver: zodResolver(formSchema),

    defaultValues: {
      name: event?.name || "",
      description: event?.description || "",
      ruleTemplateKey: event?.ruleTemplateKey || "tournament-1",
      gameKey: event?.gameKey || "sf6",
      visibility: event?.visibility || "public",
      // attendees: event?.attendees || [],
      attendeeIds: event?.attendeeIds || [],
      poster: "https://oss.laf.run/cd6zkd-user-upload-images/posts/sf6-1.png",
      registrationPage: false,
    },
  })

  const onCreate = async () => {
    let result = await form.trigger()
    if (!result) { return }
    let values = form.getValues()
    let body = {
      name: values.name,
      description: values.description,
      gameKey: values.gameKey,
      ruleTemplateKey: values.ruleTemplateKey,
      visibility: values.visibility,
      poster: values.poster,
      attendeeIds: values.attendeeIds,
      registrationPage: values.visibility === "public" ? values.registrationPage : false,
    }
    let { data } = await post("/events/add", body)
    navigate(`/events/${data}`, { replace: true })
  }

  let watch = form.watch()

  const playerFinder = (p) => {
    if (allPlayers) {
      return allPlayers.find(i => i.id === p)
    }
  }

  return (
    <div>
      <HeaderText>创建新赛事</HeaderText>
      <Form {...form}>
        <form className="" onSubmit={(e) => { e.preventDefault() }} >
          <div className='flex mt-8 phone:flex-col phone:p-2'>
            <div className="pad:w-36 w-48 text-xl self-start flex shrink-0">基本信息</div>

            <div className='flex-grow phone:mt-4 space-y-8'>
              {/* 赛事名称 */}
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem className="">
                    <FormLabel>名称</FormLabel>
                    <FormControl>
                      <Input {...field}
                        placeholder=""
                        onChange={e => {
                          field.onChange(e)
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* 游戏或运动项目 */}
              <FormField
                control={form.control}
                name="gameKey"
                render={({ field }) => {
                  let gameLabel = finder(field.value, games)?.label
                  return (
                    <FormItem className="">
                      <FormLabel>游戏或运动</FormLabel>
                      <Popover>
                        <PopoverTrigger asChild >
                          <FormControl>
                            <Button
                              variant="outline"
                              role="combobox"
                              className={cn(
                                "w-full justify-between",
                                { "text-muted-foreground": !field.value }
                              )}
                            >
                              {field.value ? gameLabel : "Select game"}
                              <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="p-0 w-full" align="start">
                          <Command>
                            <CommandInput placeholder="Search game..." />
                            <CommandEmpty>未找到相关的游戏或运动项目</CommandEmpty>
                            <CommandGroup>
                              {games.map(i => (
                                <CommandItem
                                  key={i.key}
                                  value={i.label}
                                  onSelect={() => {
                                    let game = finder(i.key, games)
                                    form.setValue("gameKey", i.key)
                                    form.setValue("poster", game.posters[0] || "")
                                  }}
                                >
                                  <Check
                                    className={cn("mr-2 h-4 w-4",
                                      { "opacity-100": i.key === field.value },
                                      { "opacity-0": i.key !== field.value },
                                    )}
                                  />
                                  {i.label}
                                </CommandItem>
                              ))}
                            </CommandGroup>
                          </Command>
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />

              {/* 规则 */}
              <FormField
                control={form.control}
                name="ruleTemplateKey"
                render={({ field }) => (
                  <FormItem className="space-y-3">
                    <FormLabel>规则</FormLabel>
                    <FormControl>
                      <div className="">
                        <div className='grid grid-cols-3 gap-4 phone:grid-cols-none'>
                          {rules.map(i => {
                            return (
                              <Button key={i.key}
                                variant={i.key === field.value ? "default" : "secondary"}
                                onClick={() => {
                                  form.setValue("ruleTemplateKey", i.key)
                                  field.onChange(i.key)
                                }}
                              >
                                {i.title}
                              </Button>
                            )
                          })}
                        </div>
                        <div className="mt-2 text-sm text-secondary-foreground">
                          {finder(field.value, rules)?.description}
                        </div>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* 海报 */}
              <FormField
                control={form.control}
                name="poster"
                render={({ field }) => (
                  <FormItem className="flex-grow">
                    <FormLabel>海报</FormLabel>
                    <FormControl>
                      <PosterPicker field={field} posters={finder(form.watch("gameKey"), games)?.posters || []} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* 描述 */}
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem className="">
                    <FormLabel>描述</FormLabel>
                    <FormControl>
                      <Textarea {...field}
                        onChange={e => {
                          field.onChange(e)
                        }}
                        className="resize-none"
                        placeholder=""
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* 可见性 visibility */}
              <FormField
                control={form.control}
                name="visibility"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>隐私</FormLabel>
                    <FormControl>
                      {/* <ToggleGroup type="single" value={field.value} onValueChange={(e) => {}}>
                        <ToggleGroupItem value="publish">公开</ToggleGroupItem>
                        <ToggleGroupItem value="private">不公开</ToggleGroupItem>
                      </ToggleGroup> */}

                      <RadioGroup value={field.value} onValueChange={(e) => field.onChange(e)}>
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="public" />
                          </FormControl>
                          <FormLabel className="">公开访问，管理员可修改</FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="private" />
                          </FormControl>
                          <FormLabel className="">私有，仅限管理员可访问</FormLabel>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

            </div>
          </div>

          <div className='flex mt-16 phone:flex-col phone:p-2'>
            <div className="pad:w-36 w-48 text-xl self-start flex shrink-0">选手</div>
            <div className='flex-grow phone:mt-4'>

              {/* <FormField
                control={form.control}
                name="attendees"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel >
                      <div className="relative">
                        <div>
                          <span>
                            {field.value.length === 0 ? "暂无选手报名" : `已有 ${field.value.length} 名选手报名`}
                          </span>
                        </div>
                      </div>
                    </FormLabel>
                    <FormControl>
                      <div className='border w-full p-2 rounded-xl'>
                        <div className="flex flex-wrap gap-1 min-h-10">
                          {field.value.map(i => (
                            <PlayerSmallCard
                              className="bg-secondary group relative"
                              key={i.id}
                              player={i}
                              appendRender={(
                                <div className="space-x-1 absolute right-1 z-10">
                                  <Button variant="ring" size="icon" className="rounded-full w-6 h-6 opacity-0 group-hover:opacity-100"
                                    onClick={() => {
                                      console.log('field.value', field.value)
                                      field.onChange(field.value.filter(j => j.id !== i.id))
                                    }}
                                  >
                                    <Minus className="h-3 w-3" />
                                  </Button>
                                </div>
                              )}
                            />
                          ))}

                          <Modal
                            title="为选手报名"
                            triggerButton={(
                              <Button className="" variant="ghost">
                                <Plus className="mr-1 w-4 h-4" />
                                <span>添加选手</span>
                              </Button>
                            )}>
                            <PlayersPicker value={field.value} onChange={field.onChange} />
                          </Modal>
                        </div>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              /> */}
              <FormField
                control={form.control}
                name="attendeesIds"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel >
                      <div className="relative">
                        <span>
                          {field.value?.length ? `已有 ${field.value?.length} 名选手报名` : "暂无选手报名"}
                        </span>
                      </div>
                    </FormLabel>
                    <FormControl>
                      <div className='border w-full p-2 rounded-xl'>
                        <div className="flex flex-wrap gap-1 min-h-10">
                          {field.value?.map(i => (
                            <PlayerSmallCard
                              className="bg-secondary group relative"
                              key={i}
                              player={playerFinder(i)}
                              appendRender={(
                                <div className="space-x-1 absolute right-1 z-10">
                                  <Button variant="ring" size="icon" className="rounded-full w-6 h-6 opacity-0 group-hover:opacity-100"
                                    onClick={() => {
                                      // console.log('field.value', field.value)
                                      field.onChange(field.value.filter(j => j !== i))
                                    }}
                                  >
                                    <Minus className="h-3 w-3" />
                                  </Button>
                                </div>
                              )}
                            />
                          ))}
                          <Modal
                            title="为选手报名"
                            triggerButton={(
                              <Button className="" variant="ghost">
                                <Plus className="mr-1 w-4 h-4" />
                                <span>添加选手</span>
                              </Button>
                            )}>
                            <PlayersPicker value={field.value} onChange={field.onChange} option={{ cleanable: true }} />
                          </Modal>
                        </div>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {watch.visibility === "public" && (
                <FormField
                  control={form.control}
                  name="registrationPage"
                  render={({ field }) => (
                    <FormItem className='mb-8 mt-8'>
                      <FormLabel className="block">
                        提供自主报名界面
                      </FormLabel>
                      <FormControl>
                        <label className="flex items-center">
                          <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                          <span className="ml-2 text-xs text-secondary-foreground select-none">用户可通过访问网址自行注册报名</span>
                        </label>
                      </FormControl>
                    </FormItem>
                  )}
                />
              )}

              <div className="p-4 rounded-lg bg-secondary text-sm ">
                主办方可以在赛事创建后自行调整选手名单
              </div>
            </div>
          </div>
        </form>
      </Form>

      <div className='flex mt-16 phone:flex-col phone:p-2'>
        <div className="pad:w-36 w-48 text-xl self-start flex shrink-0">最终确认</div>
        <div className="w-full">
          <FormField
            control={form.control}
            name=""
            render={() => {
              // let watch = form.watch()
              return (
                <FormItem>
                  <EventCell className="bg-secondary rounded-xl transition-all"
                    event={{
                      name: watch.name,
                      gameName: finder(watch.gameKey, games)?.label,
                      stageLabel: "报名阶段",
                      rulesText: finder(watch.ruleTemplateKey, rules)?.title,
                      description: watch.description,
                      registrationText: `${watch.attendeeIds.length} 位选手已报名`,
                      organizer: { displayName: "你" },
                      poster: watch.poster,
                    }}
                  />
                </FormItem>
              )
            }}
          />

          <Button className="w-full mt-4" onClick={onCreate}>
            创建比赛
          </Button>
        </div>
      </div>
    </div>
  )
}