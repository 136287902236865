import React, { useRef } from 'react'
import { Settings, Share2 } from 'lucide-react'
import { Button } from '@/shadcn/components/ui/button'
import { Modal } from '@/shadcn/components/_modal'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from '@/shadcn/components/_tooltip'
import { toast } from 'sonner'
import { EventEditor } from '../../form-editor'
import { Confirm } from '@/shadcn/components/_confirm'


export const HeaderButtons = ({ event, mutate, matching }) => {
  const navigate = useNavigate()
  const editorRef = useRef()


  const copyUrl = () => {
    navigator.clipboard.writeText(window.location.href)
    toast("已复制链接", { closeButton: true })
  }

  return (
    <div className='flex ml-auto items-center'>

      <Tooltip label="Copy Link">
        <Button className="mx-2" variant="outline" size="icon" onClick={copyUrl}>
          <Share2 className="h-4 w-4" />
        </Button>
      </Tooltip>

      {matching && (
        <Modal
          title="Settings"
          tooltipContent={"Settings"}
          triggerButton={(
            <Button variant="outline" size="icon"> <Settings className="h-4 w-4" /> </Button>
          )}
          render={({ close }) => {
            return (
              <div className='flex flex-col gap-4'>
                <EventEditor event={event} mutate={mutate} close={close} scene="edit" ref={editorRef} />

                <div className="border p-4 rounded-lg grid grid-cols-4">
                  <div className='col-span-1 text-secondary-foreground mr-4'>Actions</div>

                  <div className="flex flex-col col-span-3">
                    <Confirm
                      confirmText="确认，删除比赛"
                      title="确定删除比赛吗？"
                      description="删除后无法恢复，所有比赛信息会被立即删除。"
                      triggerButton={(
                        <Button className="" size="widthFull" variant="secondaryDestructive">
                          删除比赛
                        </Button>
                      )}
                      confirmAction={async () => {
                        await event.removeSelf()
                        close()
                        navigate('/events')
                      }}
                    >
                    </Confirm>
                  </div>
                </div>
              </div>
            )
          }}
        />
      )}
    </div>
  )
}

