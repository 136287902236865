import { Button } from '@/shadcn/components/ui/button'
import { cn } from '@/shadcn/utils'
import { ArrowRight } from 'lucide-react'
import React from 'react'
import { Link } from 'react-router-dom'

export const Home = () => {
  return (
    <main className='flex min-h-screen flex-col p-24 pad:p-12 phone:p-4'>

      <section className="mx-auto flex max-w-[980px] flex-col items-center gap-6 py-8 md:py-12 lg:py-24">
        <Link to="/docs/changelog" className="inline-flex items-center rounded-lg bg-muted px-3 py-1 text-sm font-medium" >
          🎉 <span className="ml-4">内测中，查看新功能</span>
          <ArrowRight className='w-4 ml-2' />
        </Link>

        <h1 className="scroll-m-20 font-extrabold tracking-tight text-4xl lg:text-5xl">
          轻松记录比分
        </h1>
        <span className="max-w-[750px] text-center text-lg text-muted-foreground sm:text-xl" >
          创建锦标赛，管理赛程，记录得分，为赛事主办方提供一站式赛事管理解决方案，适用于任何运动与赛事，提供 Web 网页程序与手机应用程序并轻松同步数据。
          （内测中，仅支持查看记分情况，不允许互联网交互。）
        </span>
        <div className="flex w-full items-center justify-center space-x-4 py-4 md:pb-10">
          <Link to="/events">
            <Button>
              查看公开赛事
            </Button>
          </Link>
          {/* <Button variant="outline">
            去登录，创建比赛
          </Button> */}
        </div>
      </section>


      <h3 className="scroll-m-20 text-2xl font-semibold tracking-tight mb-2 text-center">
        开发计划
      </h3>

      <div className='grid grid-cols-6 md:grid-cols-12 gap-2 w-full mb-4 max-w-[1080px] mx-auto'>
        <RoadmapBlock
          className="col-span-6 md:col-span-4 bg-emerald-100 dark:bg-emerald-900"
          title="👊 公开锦标赛"
          description="单败或双败赛, 4 人、8 人、16 人、32 人、64 人..."
        />
        <RoadmapBlock
          className="col-span-3 bg-emerald-100 dark:bg-emerald-900"
          title="🏄‍♀️ 🏄 🏄‍♂️ 选手"
          description="快捷添加选手并为其报名比赛"
        />
        <RoadmapBlock
          className="col-span-3 bg-emerald-100 dark:bg-emerald-900"
          title="📝 记分板"
          description="适配多种设备屏幕展示比分"
        />
        <RoadmapBlock
          className="col-span-2 bg-emerald-100 dark:bg-emerald-900"
          title="🌞🌛 深色/浅色"
          description="跟随系统或自定义"
        />
        <RoadmapBlock
          className="col-span-4 md:col-span-2 bg-muted"
          title="📱 手机应用程序"
          description="优秀体验，也更方便"
        />
        <RoadmapBlock
          className='col-span-4 md:col-span-4 bg-muted'
          title="🖇️ 微信小程序"
          description="更简单地接受报名与分享"
        />
        <RoadmapBlock
          className='col-span-2 md:col-span-4 bg-muted'
          title="⚽️ 🏀 体育"
          description="支持现实中的体育赛事规则"
        />
        <RoadmapBlock
          className='col-span-6 md:col-span-2 bg-muted'
          title="🥇 🥈 🥉 积分赛"
          description="支持分组积分赛和更多赛制"
        />
        <RoadmapBlock
          className='col-span-2 md:col-span-3 bg-muted'
          title="⌛️ 计时赛"
          description="同时支持计时和记分规则"
        />
        <RoadmapBlock
          className='col-span-4 md:col-span-5 bg-muted'
          title="🎖️ 系列赛"
          description="为品牌建立一系列赛事并记录历史数据"
        />
        <RoadmapBlock
          className='col-span-6 md:col-span-4 bg-muted'
          title="🔥 API"
          description="被集成到更多应用"
        />
      </div>

      <ICPFooter />
      {/* </div> */}
    </main>
  )
}

const RoadmapBlock = ({ className, title, description }) => {
  return (
    <div className={cn("h-32 rounded-lg p-4 pad:p-2 phone:p-1 flex-center flex-col", className)}>
      <div className='mb-2'>{title}</div>
      <div className='text-xs text-center'>{description}</div>
    </div>
  )
}

const ICPFooter = () => {
  return (
    <p className="mt-auto text-sm text-gray-500 flex justify-center space-x-1 sm:flex-row sm:space-x-4">
      <a
        className="hover:text-primary flex justify-center items-center text-xs"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502049965"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={"images/beian.png"} className="w-3 h-3 mr-1" alt="公安警徽" />
        京公网安备11010502054790号
      </a>

      <a
        href="https://beian.miit.gov.cn"
        target="_blank"
        rel="noopener noreferrer"
        className="hover:text-primary text-xs"
      >
        京ICP备2022017891号-3
      </a>
    </p>
  )
}
