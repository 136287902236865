import { Button } from '@/shadcn/components/ui/button'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/shadcn/components/ui/form'
import * as z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from 'react-hook-form'
import { InputOTP, InputOTPGroup, InputOTPSlot } from '@/shadcn/components/ui/input-otp'
import { useToggle } from 'react-use'
import { sendEmailVerifyCode } from '@/components/laf-auth/account'
import { toast } from 'sonner'


const formSchema = z.object({
  code: z.string().length(6, "请输入完整的 6 位验证码"),
})

export const VerifyEmailForm = ({ account, mutate, close }) => {
  let [isSending, setIsSending] = useToggle(false)


  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: { code: "", },
  })

  const onSendCode = async () => {
    let { code, message } = await sendEmailVerifyCode(account.email)
    if (code === 1) {
      toast("验证码已发送，请查收邮件。", { closeButton: true })
      setIsSending()
    } else {
      toast(message || "发送失败，请确认邮箱地址是否正确。", { closeButton: true })
    }
  }

  const onSubmit = async (values) => {
    let { code } = values
    // 根据返回值弹出是否核验成功，如果成功的话，关闭弹窗，刷新数据
    let result = await account.verifyEmail(code)

    if (result.code === 1) {
      toast("邮箱验证成功，您可以使用邮箱地址登录了。", { closeButton: true })
      await mutate()
      close()
    } else {
      toast(result.message, { closeButton: true })
    }
  }

  return (
    <Form {...form} >
      <FormItem className="flex flex-row items-center justify-between rounded-lg bg-muted p-4">
        <div className="space-y-0.5">
          <FormLabel>Step 1</FormLabel>
          <FormDescription>
            点击右侧按钮，向 {account.email} 发送 6 位验证码
          </FormDescription>
        </div>
        <FormControl>
          <Button
            disabled={isSending}
            className="ml-12"
            onClick={() => onSendCode()}
          >
            {isSending ? "已发送验证码" : "发送验证码"}
          </Button>
        </FormControl>
      </FormItem>

      <form className="space-y-4" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="code"
          render={({ field }) => (
            <FormItem className="flex flex-col rounded-lg bg-muted p-4">
              <FormLabel>Step 2</FormLabel>
              <div className="space-y-0.5 flex flex-center flex-col">
                <FormDescription className="pb-2">
                  在这里填写验证码
                </FormDescription>
                <FormControl >
                  <InputOTP maxLength={6}
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value)
                      if (value.length === 6) {
                        form.handleSubmit(onSubmit)()
                      }
                    }}
                  >
                    <InputOTPGroup >
                      <InputOTPSlot index={0} className="bg-background" />
                      <InputOTPSlot index={1} className="bg-background" />
                      <InputOTPSlot index={2} className="bg-background" />
                      <InputOTPSlot index={3} className="bg-background" />
                      <InputOTPSlot index={4} className="bg-background" />
                      <InputOTPSlot index={5} className="bg-background" />
                    </InputOTPGroup>
                  </InputOTP>
                </FormControl>
                <FormMessage />
              </div>
            </FormItem>

          )}
        />
      </form>
    </Form >
  )
}