import { cn } from "@/shadcn/utils"

export function HeaderText({ children, className }) {
  return (
    <h1 className={cn(
      "font-bold tracking-tighter text-4xl leading-tight lg:text-6xl lg:leading-normal pr-2",
      "bg-clip-text text-transparent bg-gradient-to-r from-zinc-700 to-gray-500 dark:from-white dark:to-gray-600",
      className,
    )}>
      {children}
    </h1>
  )
}
