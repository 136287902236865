import { forwardRef, useImperativeHandle, useState } from 'react'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '@/shadcn/components/ui/dialog'
import { RefPackaging } from '@/shadcn/utils'
import { Drawer, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle, DrawerTrigger } from '@/shadcn/components/ui/drawer'
import { useMedia } from 'react-use'

function ModalRef(props, ref) {
  let { triggerButton, } = props
  let { title, description } = props
  let { triggerClass, dialogClass } = props
  let [open, setOpen] = useState(false)
  const isDesktop = useMedia('(min-width: 450px)')

  useImperativeHandle(ref, () => {
    return { setOpen }
  }, [])

  if (isDesktop) {
    return (
      <Dialog open={open} onOpenChange={setOpen} >
        <DialogTrigger asChild className={triggerClass}>
          <RefPackaging  >
            <>
              {triggerButton}
              {props.renderTrigger?.({
                open: () => setOpen(true),
              })}
            </>
          </RefPackaging>
        </DialogTrigger>
        <DialogContent className={dialogClass}>
          <DialogHeader>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogDescription>{description}</DialogDescription>
          </DialogHeader>
          {props.children}
          {props.render?.({
            close: () => setOpen(false),
          })}

        </DialogContent>
      </Dialog >
    )
  } else {
    return (
      <Drawer open={open} onOpenChange={setOpen}>
        <DrawerTrigger asChild className={triggerClass}>
          <RefPackaging>
            <>
              {triggerButton}
              {props.renderTrigger?.({
                open: () => setOpen(true),
              })}
            </>
          </RefPackaging>
        </DrawerTrigger>
        <DrawerContent className="mx-2">
          <DrawerHeader>
            {title && <DrawerTitle>{title}</DrawerTitle>}
            {description && <DrawerDescription>{description}</DrawerDescription>}
          </DrawerHeader>
          <div className='px-6 pb-20'>
            {props.children}
            {props.render?.({
              close: () => setOpen(false),
            })}
          </div>
          {/* <DrawerFooter>
            <DrawerClose>
              <button variant="outline">Cancel</button>
            </DrawerClose>
          </DrawerFooter> */}
        </DrawerContent>
      </Drawer>
    )
  }
}

export const Modal = forwardRef(ModalRef)


