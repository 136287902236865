export const whyNeedLogin = {
  title: "为何须登录才能使用应用程序",
  key: "why-need-login",
  to: "/docs/why-need-login",
  date: "2024-04-05",
  body: `
# 为何须登录才能使用应用程序

技术上，目前仅支持将数据通过云服务储存在服务器中，这会导致您需要登录才能使用正赛。

这种方式使得应用程序获得如下好处：

1. **数据同步和云服务**：将您的数据同步到云端，确保您可以通过网页或手机应用程序随时访问数据。这样，即使您更换设备或手机，您的数据也能得到保护和恢复。

2. **必要的数据隔离**：您作为赛事组织者或主办方，在没有手动将数据公开的情况下，正赛会确保只有您可以访问这些数据。

我们理解登录可能会带来一些不便，如果您确实非常希望免登录使用应用程序，请联系开发者提供更多反馈，我们会根据反馈调整开发计划。
`}