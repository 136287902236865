"use client"
import { UserAccount } from '@/models/user-account'
import { post } from '@/utils/laf-db'
import useSWR from 'swr'


export const useCurrentUser = () => {
  // 在外部试图访问 localStorage 会导致 next 报错
  // let token = localStorage.getItem("access_token")
  // let fetcherKey = token ? ['laf-post:check-user', token] : null

  const fetcher = async () => {
    let token = localStorage.getItem("access_token")
    if (!token) { return null }

    let accountData = localStorage.getItem("account_data")
    if (accountData) {
      let user = new UserAccount(JSON.parse(accountData))
      return user
    }
    let { data: account } = await post('/auth/check-user')
    if (account) {
      localStorage.setItem("account_data", JSON.stringify(account))
      let user = new UserAccount(account)
      return user
    } else {
      console.log("** check-user no account")
    }
  }

  const swrResult = useSWR(`laf-post:check-user`, fetcher, {
    // 禁用 3 个自动重刷策略，在开发阶段为了可控和后续省流的目的
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

  return swrResult
}

// 检查账户和资源是否匹配，true 表示拥有编辑权
export const useResourceUserMatching = (account, source) => {
  if (!source?.owner || !account?.id) {
    return { matching: false }
  }
  // 作为 owner
  if (source.owner === account.id) {
    return { matching: true }
  }
  // Todo 作为 manager
  return { matching: false }
}

