import { useRef } from 'react'
import { MatchEditor } from './form-editor'
import { useNavigate } from 'react-router-dom'
import { Button } from '@/shadcn/components/ui/button'
import { Separator } from '@/shadcn/components/ui/separator'

export const MatchSettings = ({ match, mutate, hiddenDelete, disabledChangePlayer }) => {
  const matchEditorRef = useRef()
  const navigate = useNavigate()
  return (
    <div>
      <MatchEditor match={match} scene={"edit"} ref={matchEditorRef} disabledChangePlayer={disabledChangePlayer} />
      <Separator className="my-8" />
      <div className="flex gap-4">
        {hiddenDelete !== true && (
          <Button className="w-full" variant="secondaryDestructive" onClick={async () => {
            await match.removeSelf()
            await mutate()
            navigate("/matches")
          }}>
            Delete
          </Button>
        )}

        <Button className="w-full"
          onClick={async () => {
            let { saveChange } = matchEditorRef.current
            saveChange(match.id, async () => {
              await mutate()
              // close()
            })
          }}>
          Save
        </Button>
      </div>
    </div>
  )
}

