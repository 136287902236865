import React from 'react'
import { Carousel, CarouselConfirmPrevious, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/shadcn/components/ui/carousel'
import { stageMapper } from '@/models/game-event'
import { toast } from 'sonner'

export const Stage = ({ event, mutate, matching }) => {
  return (
    <Carousel
      opts={{
        startIndex: stageMapper[event?.stage]?.index,
        duration: 15,
        watchDrag: false,
        loop: false
      }}
      className="w-20 md:w-28"
    >
      <CarouselContent >
        {Object.values(stageMapper).map(i => {
          return <CarouselItem key={i.label} className="flex justify-center truncate">{i.label}</CarouselItem>
        })}
      </CarouselContent>

      {matching && event?.stage === "1-REGISTRATION" && (
        <>
          <CarouselNext action={async () => {
            let { result, message } = event.verifyMatchGenerate()
            if (result === true) {
              await event.generateMatches()
              await event.setStage("2-CONFIRM-SEAT")
              mutate()
            } else {
              toast(message, { closeButton: true })
            }
          }} />
        </>
      )}
      {matching && event?.stage === "2-CONFIRM-SEAT" && (
        <>
          <CarouselConfirmPrevious
            confirmOption={{
              title: "确定返回到“报名阶段”吗？",
              description: "当前记分的所有数据会被清除",
              confirmText: "是，返回报名阶段",
            }}
            action={async () => {
              await event.removeMatches()
              await event.setStage("1-REGISTRATION")
              mutate()
            }}
          />
          <CarouselNext action={async () => {
            await event.setStage("3-INPROGRESS")
            mutate()
          }} />
        </>
      )}

      {matching && event?.stage === "3-INPROGRESS" && (
        <>
          <CarouselPrevious
            action={async () => {
              await event.setStage("2-CONFIRM-SEAT")
              mutate()
            }}
          />
          <CarouselNext action={async () => {
            await event.setStage("4-FINISHED")
            mutate()
          }} />
        </>
      )}
      {matching && event?.stage === "4-FINISHED" && (
        <>
          <CarouselPrevious
            action={async () => {
              await event.setStage("3-INPROGRESS")
              mutate()
            }}
          />
        </>
      )}
    </Carousel>

  )
}

