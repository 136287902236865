import React from 'react'
import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

// 必须要这样包装一下才不会在嵌套 asChild 时报错
function Packaging(props, forwardedRef) {
  return <div className="inline-block" {...props} ref={forwardedRef} />
}
const RefPackaging = React.forwardRef(Packaging)
export { RefPackaging }

