import { usePlayers } from '@/hooks/use-players'
import { Button } from '@/shadcn/components/ui/button'
import { HeaderText } from '@/components/header-text'
// import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/shadcn/components/ui/dialog'
import { RegisterPlayers } from './form-register-players'
import { PlayerSmallCard } from '@/components/player-card'
import { Plus, RotateCcw, User, Users2 } from 'lucide-react'
import { Modal } from '@/shadcn/components/_modal'
import { PlayerEditor } from './form-editor'
import { Confirm } from '@/shadcn/components/_confirm'
import { ContentUnavailable } from '@/shadcn/components/_content-unavailable'
import { Link } from 'react-router-dom'


// 其实未登录的用户无法进入这里
export function PlayerListPage() {
  const { data: players, mutate } = usePlayers()
  // const { data: accountPlayer } = useAccountAsPlayer()
  // const [showAppendPlayers, setShowAppendPlayers] = useState(false)
  // const { data: account } = useCurrentUser()

  return (
    <div>
      <div className="flex items-center mb-8">
        <HeaderText>选手</HeaderText>

        <div className="flex ml-auto space-x-2">
          <Button variant="outline" size="icon" onClick={mutate} >
            <RotateCcw className='w-4 h-4' />
          </Button>

          <Modal
            title="添加选手"
            triggerButton={(
              <Button variant="outline" size="icon" className="ml-auto" >
                <Plus className='w-4 h-4' />
              </Button>
            )}
            render={({ close }) => {
              return <RegisterPlayers mutate={mutate} close={close} />
            }}
          />
        </div>
      </div>

      {/* <div className='flex gap-4'>
        {accountPlayer && (
          <div>
            <div className='font-bold'>账户即选手</div>
            <PlayerSmallCard player={accountPlayer} className="h-16 px-4 rounded-lg relative justify-center cursor-pointer border-2 bg-secondary hover:border-primary transition-all" />
          </div>
        )}
      </div> */}

      <div className='mt-8 font-bold'>选手池</div>
      <div className='grid grid-cols-5 pad:grid-cols-4 phone:grid-cols-3'>
        {Array.isArray(players) && players?.map((i) => {
          return (
            <Modal
              key={i.id}
              title={`选手设置`}
              triggerButton={(
                <div>
                  <PlayerSmallCard
                    className="relative group cursor-pointer hover:bg-secondary"
                    player={i}
                    appendRender={i.asAccount && (
                      <User className='w-4 ml-auto text-opacity-60' />
                    )}
                  />
                </div>
              )}
              render={({ close }) => {
                return (
                  <div className='flex flex-col gap-4'>
                    <PlayerEditor
                      scene="edit"
                      player={i}
                      mutate={mutate}
                      close={close}
                    />

                    {i.asAccount === false && (
                      <div className="border p-4 rounded-lg grid grid-cols-4">
                        <div className='col-span-1 text-secondary-foreground mr-4'>Actions</div>

                        <div className="flex flex-col col-span-3">
                          <Confirm
                            confirmText="确认，删除选手"
                            title="确定删除选手吗？"
                            description="删除后无法恢复，选手曾参与的比赛也将丢失选手信息。"
                            triggerButton={(
                              <Button className="" size="widthFull" variant="secondaryDestructive">
                                删除选手
                              </Button>
                            )}
                            confirmAction={async () => {
                              await i.removeSelf()
                              await mutate()
                              close()
                            }}
                          >
                          </Confirm>
                        </div>
                      </div>
                    )}

                    {i.asAccount && (
                      <div className="text-sm border p-4 rounded-lg flex flex-col gap-2">
                        <div>此选手代表您的登录用户，既可以参与本用户组织的比赛，也可以参与公开社区与其他人组织的比赛。</div>
                        <div>如需修改此账户名称等信息，可前往
                          <Link className='text-blue-500' to="/account/settings">账户设置</Link>
                          中调整。
                        </div>
                      </div>
                    )}
                  </div>
                )
              }}
            >
            </Modal>
          )
        })}
      </div>

      {(!players || players.length === 0) && (
        <ContentUnavailable
          title="暂无选手"
          icon={<Users2 className="w-12 h-12 text-muted-foreground" />}
          description="保存和选手信息，以便稍后为他们报名比赛，点击按钮开始"
          className="min-h-[75dvh]"
          action={(
            <Modal
              title="添加选手"
              triggerButton={<Button>添加选手</Button>}
              render={({ close }) => {
                return <RegisterPlayers mutate={mutate} close={close} />
              }}
            />
          )}
        />
      )}

      <div className="mt-8">
        {players?.length > 0 && (
          <div className="font-light text-sm text-muted-foreground">
            Total {players?.length} players in your account
          </div>
        )}
      </div>

    </div>
  )
}


