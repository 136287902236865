// 详情界面，只负责基本信息渲染（第一层）
import React, { useRef } from 'react'

import { cn } from '@/shadcn/utils'
import { useGameEventFullData } from '@/hooks/use-events'
import { HeaderText } from '@/components/header-text'
import { Avatar, AvatarFallback, AvatarImage } from '@/shadcn/components/ui/avatar'
import { useCurrentUser, useResourceUserMatching } from '@/hooks/use-session'
import { Attendees } from './blocks/attendees'
import { AspectRatio } from '@/shadcn/components/ui/aspect-ratio'
import { ImageWithFallback } from '@/shadcn/components/_image-with-fallback'
import { useParams } from 'react-router-dom'
import { Stage } from './blocks/stage'
import { HeaderButtons } from './blocks/header-buttons'
import { ContentUnavailable } from '@/shadcn/components/_content-unavailable'
import { MatchGraph } from './stages/match-graph-234'
import { RegistrationStage } from './stages/registration-stage-1'
import { SwissRankingTable } from './stages/swiss-ranking-234'
import { TournamentRanking } from './stages/tournament-ranking-234'
import { useRanking } from '@/hooks/use-matches'
// import { Button } from '@/shadcn/components/ui/button'


// const spacerX = (x) => x * 290
// const spacerY = (y) => y * 100

// const getMatchesContentSize = (matches) => {
//   if (!matches) {
//     return {
//       width: 300,
//       height: 300
//     }
//   }
//   let mostX = Math.max(...matches.map(i => i.x))
//   let mostY = Math.max(...matches.map(i => i.y))
//   return {
//     width: spacerX(mostX + 1) + 60,
//     height: spacerY(mostY + 1) + 160 // 这 160 人工修正，为了操作栏空间
//   }
// }

export function EventPageDetail() {
  const params = useParams()
  const { data: account } = useCurrentUser()
  const { data: event, mutate: reloadEvent, isLoading: eventLoading } = useGameEventFullData(params.id)
  const { matching } = useResourceUserMatching(account, event)
  // const [highLightMatchNumber, setHighLightMatchNumber] = useState(null)
  const { data: ranking, mutate: reloadRanking } = useRanking(event)

  const board = useRef(null)
  const reload = async () => {
    await reloadEvent()
    await board.current?.reload()
  }

  // 尚未获取数据
  if (!event) {
    return <></>
  }

  // 获取到了非法的空数据
  if (event.id === undefined) {
    return <ContentUnavailable title="没有找到数据" description="请刷新或稍后再尝试，如果您确信这是一个错误，请联系开发者" />
  }
  // let group = "lose"
  // let round = 4

  return (
    <div>
      <div className="flex relative mb-4">
        <div className={cn('flex items-center min-h-[7rem]', {
          "spin relative": eventLoading,
        })}>
          <HeaderText className="">{event?.name}</HeaderText>
        </div>
        {/* <div className='fixed bottom-10 left-10 z-50 bg-gray-500 p-2 rounded'>
          {group}, {round}
          <Button onClick={async () => {
            let ms = event.matchController.matches.filter(i => {
              let g = i.tournamentProperties.group === group
              let r = i.tournamentProperties.round === round
              return g && r
            })

            let moves = ms.map(i => {
              i.moveScore({ value: 1, index: 0 })
            })
            await Promise.all(moves)
          }}>自动加分</Button>
          <Button onClick={async () => {
            let ms = event.matchController.matches.filter(i => {
              let g = i.tournamentProperties.group === group
              let r = i.tournamentProperties.round === round
              return g && r
            })
            let promotes = ms.map(i => {
              i.playersPromote()
              i.markStage("finished")
            })
            await Promise.all(promotes)
          }}>自动晋级</Button>
        </div> */}
        <HeaderButtons event={event} mutate={reload} matching={matching} />
      </div>

      <div className="grid gap-3 grid-cols-12">
        {/* Poster */}
        <div className={cn("col-start-1 col-end-7 md:col-end-4 row-span-4",
          "bento-border flex flex-col justify-center", {
        })}>
          <AspectRatio ratio={1} className="p-1">
            <div className="relative w-full h-full">
              <ImageWithFallback
                src={event?.poster}
                sizes="256w"
                className="rounded-lg object-cover"
                alt="event poster"
              />
            </div>
          </AspectRatio>
        </div>

        {/* Organizer */}
        <div className='col-start-7 md:col-start-4 col-end-13 md:col-end-7 row-start-1 row-end-2 bento-border flex-center min-h-[6rem] space-x-2 px-4'>
          <Avatar className='w-10 h-10 border-2 border-white' >
            <AvatarImage src={event.organizer?.avatar} alt="Organizer Avatar" className="" />
            <AvatarFallback>{event.organizer?.displayName?.slice?.(0, 1)}</AvatarFallback>
          </Avatar>
          <div className='flex flex-col w-0 flex-grow'>
            <div className='text-xs text-muted-foreground'>Organizer by</div>
            <div className='text-2xl truncate'>{event.organizer?.displayName}</div>
          </div>
        </div>

        <div className={cn('col-start-7 md:col-start-4 col-span-6 md:col-span-3 row-start-2 row-end-5 min-h-[4rem] bento-border relative flex-center', {
          "spin": eventLoading,
        })}>
          <Stage event={event} mutate={reload} matching={matching} />
        </div>

        {/* Text Description */}
        <div className={cn("col-span-12 md:col-span-6 row-span-4",
          "bento-border p-4 flex flex-col justify-center", {
          "spin relative": eventLoading
        })}>
          {event && (
            <>
              <div className="">{event.gameName}, {event.rulesText}</div>
              <div className="">{event.registrationText}</div>
              <div className="">{event.visibility}</div>
              {event.description && <div>{event.description}</div>}
            </>
          )}
        </div>

        {/* Attendees */}
        <div className={cn('col-span-12 bento-border', {
          "md:col-start-8 md:col-span-5 md:max-h-[520px]": event?.stage === "1-REGISTRATION",
        })}>
          <Attendees event={event} mutate={reload} matching={matching} />
        </div>

        <div className={cn('bento-border col-span-12 relative bg-background',
          { "md:col-start-1 md:col-end-8 md:row-start-5": event?.stage === "1-REGISTRATION", })}
        >
          {event?.stage === "1-REGISTRATION" && <RegistrationStage event={event} reload={reload} matching={matching} />}
          {(event?.stage !== "1-REGISTRATION") && (
            <MatchGraph event={event} mutate={async () => {
              await reloadEvent()
              await reloadRanking()
            }} matching={matching} />
          )}
        </div>

        {event.ruleTemplateKey === "swiss-1" && (
          <div className={cn('bento-border col-span-12')}>
            <SwissRankingTable event={event} ranking={ranking} mutate={reloadRanking} />
          </div>
        )}
        {(event.ruleTemplateKey === "tournament-1" || event.ruleTemplateKey === "tournament-2") && event.stage === "4-FINISHED" && (
          <div className={cn('bento-border col-span-12')}>
            <TournamentRanking event={event} />
          </div>
        )}
      </div>
    </div>
  )
}

