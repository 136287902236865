import React from 'react'
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '@/shadcn/components/ui/table'
import { PlayerAvatar } from '@/shadcn/components/_player-avatar'
// import { useRanking } from '@/hooks/use-matches'
import { getWinnerLoserByScore } from '@/models/match'
import { cn } from '@/shadcn/utils'
import { useFrontendRanking } from '@/hooks/use-fe-ranking'


// 纯展示，根据 event 核心数据计算出对应排名的 ranking 视图，基本没有需要操作的点
const useOpponentNames = (event, ranking) => {
  let names = {}
  const allMatches = event?.matchController?.matches || []

  ranking?.forEach?.(i => {
    let playerRecords = allMatches.filter(m => m.scoreboard.some(s => s.playerId === i.playerId))

    let opponentNames = playerRecords
      .map(m => {
        let { loser } = getWinnerLoserByScore(m.scoreboard)
        return m.scoreboard.map(s => {
          return { name: s.player?.name, lose: s?.playerId === loser?.playerId }
        })
      })
      .flat()
      .filter(n => n.name && n.name !== i.player?.name)
    names[i.playerId] = opponentNames
  })
  return names
}

export function TournamentRanking({ event }) {
  // const { data: ranking, mutate } = useRanking(event)
  const { ranking } = useFrontendRanking(event)
  const names = useOpponentNames(event, ranking)

  if (!Array.isArray(ranking)) {
    return <></>
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="phone:p-1 phone:text-xs phone:w-12 w-20 text-center">排名</TableHead>
          <TableHead className="phone:p-1 phone:text-xs md:w-56">选手</TableHead>
          {/* <TableHead className="phone:p-1 phone:text-xs text-center md:w-40" >胜-负</TableHead> */}
          <TableHead className="text-end w-48 phone:hidden">对阵选手</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {ranking.map((i, index) => (
          <TableRow key={`tournament-ranking-table-${index}`} className="">
            <TableCell className="phone:p-1 phone:w-12 text-center font-number text-lg">{i.rank}</TableCell>
            <TableCell className="phone:p-1 phone:max-w-20">
              <div className='flex items-center '>
                <PlayerAvatar player={i.player} className="w-5 h-5 mr-1 phone:hidden" />
                <span>{i.player?.name}</span>
              </div>
            </TableCell>
            <TableCell className="text-xs text-end p-1 leading-4 flex flex-wrap justify-end phone:hidden">
              {names[i.playerId]?.map((opponent, index) => (
                <span key={`opponent-${index}`}
                  className={cn("rounded text-nowrap p-1 m-0.5 bg-secondary", {
                    "bg-winner": opponent.lose === true,
                  })}
                >
                  {opponent.name}
                </span>
              ))}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>

      <TableCaption className="mb-4">
        <span>
          以上为选手排名与数据
        </span>
        {/* <Button variant="link" onClick={mutate}>刷新</Button> */}
      </TableCaption>
    </Table>
  )
}
