import { forwardRef } from 'react'
import * as z from "zod"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/shadcn/components/ui/form'
import { useForm } from 'react-hook-form'
import { zodResolver } from "@hookform/resolvers/zod"
import { Input } from '@/shadcn/components/ui/input'
import { post } from '@/utils/laf-db'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/shadcn/components/ui/select'
import { PlayerAvatar } from '@/shadcn/components/_player-avatar'
import { Button } from '@/shadcn/components/ui/button'


// tailwind 爬颜色脚本 https://tailwindcss.com/docs/customizing-colors
// let list = []
// for (let i of all) {
//   let colors = i.children[1].children[3].children[0].children[1].children[1].innerText
//   list.push(colors)
// }
// console.log(list)

// 小写字符串
// "slate",
// "gray",
// "zinc",
// "neutral",
// "stone",
// "red",
// "orange",
// "amber",
// "yellow",
// "lime",
// "green",
// "emerald",
// "teal",
// "cyan",
// "sky",
// "blue",
// "indigo",
// "violet",
// "purple",
// "fuchsia",
// "pink",
// "rose"

export const playerThemeColors = {
  // "Slate": { light: "#cbd5e1", dark: "#475569" },
  // "Gray": { light: "#d1d5db", dark: "#4b5563" },
  "Zinc": { light: "#d4d4d8", dark: "#52525b" },
  // "Neutral": { light: "#d4d4d4", dark: "#525252" },
  "Stone": { light: "#d6d3d1", dark: "#57534e" },
  "Red": { light: "#fca5a5", dark: "#dc2626" },
  "Orange": { light: "#fdba74", dark: "#ea580c" },
  "Amber": { light: "#fcd34d", dark: "#d97706" },
  "Yellow": { light: "#fde047", dark: "#ca8a04" },
  "Lime": { light: "#bef264", dark: "#65a30d" },
  "Green": { light: "#86efac", dark: "#16a34a" },
  "Emerald": { light: "#6ee7b7", dark: "#059669" },
  "Teal": { light: "#5eead4", dark: "#0d9488" },
  "Cyan": { light: "#67e8f9", dark: "#0891b2" },
  "Sky": { light: "#7dd3fc", dark: "#0284c7" },
  "Blue": { light: "#93c5fd", dark: "#2563eb" },
  "Indigo": { light: "#a5b4fc", dark: "#4f46e5" },
  "Violet": { light: "#c4b5fd", dark: "#7c3aed" },
  "Purple": { light: "#d8b4fe", dark: "#9333ea" },
  "Fuchsia": { light: "#f0abfc", dark: "#c026d3" },
  "Pink": { light: "#f9a8d4", dark: "#db2777" },
  "Rose": { light: "#fda4af", dark: "#e11d48" },
}

const formSchema = z.object({
  name: z.string().min(1, {
    message: "Player Name must be at least 1 character.",
  }),
  color: z.string()
})

export const PlayerEditor = forwardRef(({ player, scene, mutate, close }, ref) => {
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: player?.name || "",
      color: player?.color || playerThemeColors["Zinc"],
    },
  })

  const saveChange = async (id, call) => {
    let result = await form.trigger()
    if (!result) { return }
    let values = form.getValues()
    let body = {
      id: id,
      name: values.name,
      color: values.color,
    }
    await post('/players/set', { players: [body] })
    call()
  }

  const disabledForm = player.asAccount === true

  // scene: create, edit
  if (scene !== "create" && scene !== "edit") {
    return <>need scene props</>
  }

  return (
    <Form {...form} disabled={true}>
      <form className="grid grid-cols-4 border p-4 rounded-lg bg-secondary">
        <div className='col-span-1 flex items-center'>
          <PlayerAvatar
            player={player}
            className={"w-20 h-20 phone:w-16 phone:h-16"}
            fallbackClassName={"text-xl"}
          />
        </div>

        <div className="space-y-4 col-span-3">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>选手名称</FormLabel>
                <FormControl>
                  <Input {...field} disabled={disabledForm} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="color"

            render={({ field }) => {
              return (
                <FormItem className="flex flex-col relative">
                  <FormLabel>Color</FormLabel>
                  <Select
                    // open={true}
                    // {...field} // 不能通过这种方式展开，会报错
                    // disabled={scene === "create"} // maybe hidden
                    disabled={disabledForm}
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Choose player theme color" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent className="h-64" >
                      {Object.entries(playerThemeColors).map(([k, v]) => {
                        return <SelectItem key={k} value={k} className="">
                          <div className='flex items-center space-x-2'>
                            <div style={{ background: `linear-gradient(to bottom, ${v.light}, ${v.dark})` }}
                              className={`h-3 w-3 rounded-full`}
                            />
                            <div>{k}</div>
                          </div>
                        </SelectItem>
                      })}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )
            }}
          />


          {disabledForm === false && (
            <Button className="w-full" size=""
              onClick={async (e) => {
                e.preventDefault()
                saveChange(player.id, async () => {
                  await mutate()
                  close()
                })
              }}>
              保存
            </Button>
          )}
        </div>
      </form>
    </Form>
  )
})

