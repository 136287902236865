import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { AccountProvider } from '@/components/laf-auth/account'
import { RouterProvider, } from "react-router-dom"
import { ThemeProvider } from '@/shadcn/theme-provider'
import { Toaster } from './shadcn/components/ui/sonner'
import { initApp } from './utils/app-init'
import { router } from './router'

import './globals.css'
import './globals-shadcn.css'

initApp()


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <AccountProvider>
      <ThemeProvider defaultTheme="dark" storageKey="ui-theme">
        <Toaster />
        <div className='flex phone:flex-col'>
          <div className='block phone:hidden w-[58px] h-1 opacity-0 bg-red-700 pointer-events-none shrink-1 flex-shrink-0' />

          <div className='w-full h-full' >
            <RouterProvider router={router} />
          </div>
        </div>
        <div className='hidden phone:block h-[58px] w-1 opacity-0 bg-red-700 pointer-events-none shrink-1 flex-shrink-0' />
      </ThemeProvider>
    </AccountProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
