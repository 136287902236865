import { Tooltip as Tip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/shadcn/components/ui/tooltip'

export function Tooltip({ children, label, side }) {
  return (
    <TooltipProvider>
      <Tip delayDuration={100}>
        <TooltipTrigger asChild>
          {children}
        </TooltipTrigger>
        <TooltipContent side={side}>
          {label}
        </TooltipContent>
      </Tip>
    </TooltipProvider>
  )
}


