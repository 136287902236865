import useSWR from 'swr'
import { post } from "@/utils/laf-db"
import { Match } from '@/models/match'
import { Paging } from '@/models/paging'


export const useMatches = () => {
  const fetcher = async () => {
    let { data, paging } = await post('/matches/get', { type: "single", limit: 100 })
    let matches = data.map(i => {
      return new Match(i, null)
    })
    return { matches, paging: new Paging(paging) }
  }

  let swrKey = `laf-sdk:get-matches`
  const swrResult = useSWR(swrKey, fetcher, {
    // 禁用 3 个自动重刷策略，在开发阶段为了可控和后续省流的目的
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })
  return swrResult
}


export const useMatchFullData = (id) => {
  const fetcher = async () => {
    let filter = {
      "id": id
    }
    let { data: matches } = await post('/matches/get', { filter })
    if (matches[0]) {
      let result = new Match(matches[0], null)
      return result
    }
  }

  let swrKey = `laf-sdk:get-match-full-data:${id}`
  const swrResult = useSWR(swrKey, fetcher, {
    // 禁用 3 个自动重刷策略，在开发阶段为了可控和后续省流的目的
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })
  return swrResult
}

export const useRanking = (event) => {
  let eventId = event?.id

  const fetcher = async () => {
    if(!eventId){
      return []
    }
    
    let getSwissRanking = event.ruleTemplateKey === "swiss-1"
    // let getTournamentRanking = event.stage === "4-FINISHED" && (event.ruleTemplateKey === "tournament-1" || event.ruleTemplateKey === "tournament-2")

    if (getSwissRanking) {
      let { data: ranking } = await post('/public-read/swiss-ranking', { eventId })
      return ranking
    } 
    // else if (getTournamentRanking) {
    //   let { data: ranking } = await post('/public-read/tournament-ranking', { eventId })
    //   return ranking
    // }
    return []
  }

  let swrKey = `laf-sdk:get-swiss-ranking:${eventId}`
  const swrResult = useSWR(swrKey, fetcher, {
    // 禁用 3 个自动重刷策略，在开发阶段为了可控和后续省流的目的
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })
  return swrResult
}
