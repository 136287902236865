import { forwardRef, useImperativeHandle, useState } from 'react'
import * as z from "zod"
import { cn } from "@/shadcn/utils"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/shadcn/components/ui/form'
import { Button } from '@/shadcn/components/ui/button'
import { useFieldArray, useForm } from 'react-hook-form'
import { zodResolver } from "@hookform/resolvers/zod"
import { Input } from '@/shadcn/components/ui/input'
import { Popover, PopoverContent, PopoverTrigger } from '@/shadcn/components/ui/popover'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/shadcn/components/ui/command'
import { CalendarIcon, Check, ChevronDown } from "lucide-react"
import { post } from '@/utils/laf-db'
import { Textarea } from '@/shadcn/components/ui/textarea'
import { usePlayers } from '@/hooks/use-players'
import { useDebounce } from 'react-use'
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '@/shadcn/components/ui/select'
import { format } from 'date-fns'
import { Calendar } from '@/shadcn/components/ui/calendar'
import { PlayerAvatar } from '@/shadcn/components/_player-avatar'


export const matchStages = {
  "in progress": { label: "In Progress" },
  "finished": { label: "Finished" },
}

const formSchema = z.object({
  name: z.string().optional(),
  note: z.string().optional(),
  stage: z.string(),
  date: z.date(),
  scoreboard: z.array(
    z.object({
      playerId: z.string().optional(),
      value: z.string().default("0"),
    })
  )
})

export const MatchEditor = forwardRef(({ match, scene, disabledChangePlayer = false }, ref) => {

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: match?.name || "",
      note: match?.note || "",
      stage: match?.stage || "in progress",
      date: match?.date || new Date(), // Match 必须负责初始化成功，不存在 Match 的话，采用当前时间
      scoreboard: match?.scoreboard || [
        { playerId: "", value: "0" },
        { playerId: "", value: "0" },
      ],
    },
  })

  // 参考代码
  // https://react-hook-form.com/docs/usefieldarray
  // https://codesandbox.io/p/sandbox/react-hook-form-zod-with-array-of-objects-field-array-usefieldarray-8xh3ry?file=/src/App.tsx
  const { fields: scoreboard } = useFieldArray({
    control: form.control,
    name: "scoreboard",
  })

  useImperativeHandle(ref, () => {
    return {
      form: form,
      saveChange: async (id, call) => {
        let result = await form.trigger()
        if (!result) { return }
        let values = form.getValues()
        let body = {
          _id: id,
          name: values.name,
          note: values.note,
          stage: values.stage,
          date: values.date,
          scoreboard: values.scoreboard.map(i => {
            return { playerId: i.playerId || "", value: i.value || "" }
          }),
        }
        await post('/matches/set', { matches: [body] })
        call()
      },
      create: async (call) => {
        let result = await form.trigger()
        if (!result) { return }
        let values = form.getValues()
        let body = {
          name: values.name,
          note: values.note,
          stage: values.stage,
          date: values.date,
          scoreboard: values.scoreboard.map(i => {
            return { playerId: i.playerId, value: i.value }
          }),
        }
        await post('/matches/add', { matches: [body] })
        call()
      }
    }
  }, [form])

  // scene: create, edit
  if (scene !== "create" && scene !== "edit") {
    return <>need scene props</>
  }

  return (
    <Form {...form}>
      <form className="space-y-4">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>名称</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div >
          <div className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 flex items-center'>
            选手与分数
          </div>
          <div className={`mt-2 grid grid-cols-${scoreboard.length} gap-4`}>
            {Array.isArray(scoreboard) && scoreboard.map((score, index) => {
              return <div
                key={`score-items-${index}`}
                className={`col-start-${index + 1} col-end-${index + 2} flex flex-col space-y-2 shrink-0`}
              >
                <FormField
                  control={form.control}
                  name={form.register(`scoreboard.${index}.playerId`).name}
                  render={({ field }) => {
                    return <FormPlayerPicker
                      field={field}
                      form={form}
                      formKey={`scoreboard.${index}`}
                    // disabled={disabledChangePlayer}
                    />
                  }}
                />
                <FormField
                  control={form.control}
                  name={form.register(`scoreboard.${index}.value`).name}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormControl>
                          <Input
                            className="h-32 text-[80px] text-center w-full font-number"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )
                  }}
                />
              </div>
            })}
          </div >
        </div >

        <FormField
          control={form.control}
          name="note"
          render={({ field }) => (
            <FormItem>
              <FormLabel>笔记</FormLabel>
              <FormControl>
                <Textarea className="resize-none" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="grid grid-cols-2 gap-4">

          <FormField
            control={form.control}
            name="date"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>比赛日期</FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant={"outline"}
                        className={cn(
                          "pl-3 text-left font-normal",
                          !field.value && "text-muted-foreground"
                        )}
                      >
                        {field.value ? format(field.value, "yyyy-MM-dd") : <span>选择日期</span>}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={field.value}
                      onSelect={field.onChange}
                    // disabled={(date) =>
                    //   date > new Date() || date < new Date("1900-01-01")
                    // }
                    // initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />


          <FormField
            control={form.control}
            name="stage"
            render={({ field }) => {
              return (
                <FormItem className="flex flex-col">
                  <FormLabel>Stage</FormLabel>
                  <Select
                    // {...field} // 不能通过这种方式展开，会报错
                    disabled={scene === "create"} // maybe hidden
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Choose your tournament rule" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {Object.entries(matchStages).map(([k, v]) => {
                        return <SelectItem key={k} value={k}>{v.label}</SelectItem>
                      })}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )
            }}
          />
        </div>
      </form>
    </Form>
  )
})


const FormPlayerPicker = ({ field, form, formKey, className, disabled }) => {
  const [commandInput, setCommandInput] = useState("")
  const [openSelector, setOpenSelector] = useState(false)
  const [isLoading, setLoading] = useState(false)
  let { data: players, mutate: reloadList } = usePlayers({
    limit: 8,
    search: commandInput,
    swrKey: formKey,
    stopRequest: openSelector === false
  })

  let picked = form.getValues(`${formKey}.player`)

  useDebounce(async () => {
    if (openSelector) {
      await reloadList()
      setLoading(false)
    }
  }, 500, [commandInput, openSelector])

  return (
    <FormItem>
      <Popover onOpenChange={(open) => {
        setLoading(open ? true : false) // 利用 open 状态复合一个 loading
        setOpenSelector(open)
      }}>
        <PopoverTrigger asChild >
          <FormControl>
            <Button
              disabled={disabled}
              variant="outline"
              role="combobox"
              className={cn(
                "w-full justify-between relative",
                { "text-muted-foreground": !field.value },
                className,
              )}
            >
              <div
                style={{ maxWidth: "calc(100% - 0.5rem)" }}
                className={cn("truncate flex space-x-2 items-center", {
                  // "opacity-0": !picked
                })}
              >
                <PlayerAvatar player={picked} className="w-4 h-4 ml-0.5" />
                <span>{picked?.name}</span>
              </div>
              <ChevronDown className="h-4 w-4 opacity-50 absolute right-2" />
            </Button>
          </FormControl>
        </PopoverTrigger>
        <PopoverContent className={cn("p-0")}>
          <Command shouldFilter={false}>
            <CommandInput placeholder="Search" value={commandInput} onValueChange={setCommandInput} />
            <CommandList>
              <CommandEmpty>
                {isLoading ? "Loading..." : "No Data."}
              </CommandEmpty>
              <CommandGroup>
                {Array.isArray(players) && players.map((i) => (
                  <CommandItem
                    key={i.id}
                    value={i.id}
                    onSelect={() => {
                      form.setValue(`${formKey}.playerId`, i.id)
                      form.setValue(`${formKey}.player`, i)
                    }}
                  >
                    <Check
                      className={cn("h-4 w-4 absolute right-2 opacity-0",
                        { "opacity-100": i.id === field.value },
                      )}
                    />
                    <PlayerAvatar player={i} className="w-4 h-4" />
                    <span className='mx-2'>{i.name}</span>
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      <FormMessage />
    </FormItem>
  )
}