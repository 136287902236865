import React, { useState } from 'react'
import { Check, Eraser, MoveDiagonal, RotateCw, Settings } from 'lucide-react'
import { Button } from '@/shadcn/components/ui/button'
import { Modal } from '@/shadcn/components/_modal'
import { Tooltip } from '@/shadcn/components/_tooltip'
import { useCurrentUser, useResourceUserMatching } from '@/hooks/use-session'
import { getWinnerLoserByScore } from '@/models/match'
import { createGlobalState } from "react-use"
import { cn } from '@/shadcn/utils'
import { PlayerAvatar } from '@/shadcn/components/_player-avatar'
import { MatchSettings } from '@/pages/matches/match-settings'
import { FixedScoreView } from '@/pages/matches/fixed-score-view'

const useGlobalHoverId = createGlobalState("")

function CardScore({ p, dependents, win, plusScore, decreaseScore, matching, match, reload }) {
  let [value, setValue] = useGlobalHoverId()
  const adder = (
    <>
      <div onClick={() => decreaseScore(p)}
        className={cn(
          "bg-background opacity-0 hover:opacity-60",
          "absolute cursor-pointer ml-auto flex justify-center items-center transition-all",
          "rounded-lg border-2 border-primary w-5 h-5 right-9 translate-y-[5px]",
          {
            "group-hover/line:opacity-100": true,
          })}>
        -
      </div>
      <div onClick={() => plusScore(p)}
        className={cn("bg-[#c7c7c7] dark:bg-[#4c4c4c] text-foreground hover:opacity-60",
          "cursor-pointer ml-auto w-8 flex justify-center items-center flex-shrink-0",
          "font-number")}>
        {p?.value}
      </div>
    </>
  )

  const staticScoreboard = (
    <div
      className={cn("bg-[#c7c7c7] dark:bg-[#4c4c4c] text-foreground font-number",
        "ml-auto w-8 flex justify-center items-center flex-shrink-0")}>
      {p?.value}
    </div>
  )

  if (p?.player) {
    let { player } = p
    return (
      <div className='flex h-[30px] bg-input group/line'
        onMouseEnter={() => { setValue(p.playerId) }}
        onMouseLeave={() => { setValue("") }}
      >
        <div className='w-8 flex justify-center items-center flex-shrink-0'>
          <PlayerAvatar player={player} className="w-4 h-4" />
        </div>
        <div className={cn('pl-2 w-full flex items-center text-sm font-medium truncate',
          { "bg-winner border-winner": win },
          { "underline underline-offset-2 bg-yellow-300 dark:bg-yellow-700": value === p.playerId },
        )}>
          {matching ? (
            <Modal
              title={"Scoreboard"}
              triggerButton={(
                <span className="cursor-pointer">
                  {player?.name}
                  {/* {player?.name.slice(0, 4)} */}
                  {/* ({player?.id.slice(-3)}) */}
                </span>
              )}
              tooltipContent={"Scoreboard"}
              tooltipSide="left"
              render={({ close }) => {
                return (
                  <MatchSettings
                    hiddenDelete={true}
                    disabledChangePlayer={true}
                    match={match}
                    mutate={async () => {
                      await reload()
                      close()
                    }}
                  />
                )
              }}
            />
          ) : (
            <span className="">
              {player?.name}
            </span>
          )}

        </div>
        {matching ? adder : staticScoreboard}
      </div>
    )
  } else {
    return (
      <div className='flex h-[30px] bg-secondary'>
        <div className='pl-2 w-full flex items-center text-[#787A80] font-mono text-xs'>
          {dependents ? dependents.toUpperCase() : "暂无选手参赛"}
        </div>
      </div>
    )
  }
}

// MatchSmallCard 还是应该接受一组 controller 来控制状态，而不是在组件内部自行判断
export function MatchSmallCard({ match, reload, stage }) {
  let { data: account } = useCurrentUser()
  let { matching: resourceMatching } = useResourceUserMatching(account, match)
  let [loading, setLoading] = useState(false)
  const [openScoreboard, setOpenScoreboard] = useState(false)

  let [p1, p2] = match.scoreboard
  let { winner } = getWinnerLoserByScore(match.scoreboard)
  let [p1Dependents, p2Dependents] = match?.tournamentProperties?.dependents || []

  let stage3 = stage === "3-INPROGRESS"
  let matching = stage3 ? resourceMatching : false
  let showButtons = match.scoreboard.length > 0 && stage3

  const markScore = async ({ index, value }) => {
    if (loading !== false) {
      return
    }
    setLoading(true)
    await match.moveScore({ index, value })
    if (match.controller.event.ruleTemplateKey === "swiss-1") {
      await match.markStage("finished")
    }
    await reload()
    setLoading(false)

    // 点击第二下可以自动晋升
    // await match.playersPromote()
    // await match.markStage("finished")
  }

  const resetScore = async () => {
    setLoading(true)
    await match.resetScore()
    await match.markStage("in progress")
    await reload()
    setLoading(false)
  }

  const finishMatch = async () => {
    setLoading(true)
    await match.playersPromote()
    await match.markStage("finished")
    await reload()
    setLoading(false)
  }

  const unfinished = async () => {
    setLoading(true)
    await match.markStage("in progress")
    await reload()
    setLoading(false)
  }

  return (
    <div className={cn('w-[200px] rounded-[5px] overflow-hidden group', {
      "spin": loading,
    })} >
      <div className={cn('font-mono bg-secondary text-secondary-foreground text-xs px-1.5 h-[24px] top-[19px] flex justify-center items-center absolute -translate-x-[99%] rounded-l-[5px] z-20',
        { "bg-winner opacity-50": match?.stage === "in progress" },
      )}>
        <span>{match?.number}</span>
      </div>
      <div className="absolute group-hover:-top-5 opacity-20">{match?.stage}</div>
      <div className={cn("flex flex-col h-[62px] justify-between relative z-30", {
        // "opacity-60": match?.stage === "finished",
        // "grayscale-[0.5]": match?.stage === "finished",
      })}>
        <CardScore
          match={match}
          reload={reload}
          p={p1}
          dependents={p1Dependents}
          win={winner?.playerId === p1?.playerId}
          matching={matching}
          plusScore={() => markScore({ index: 0, value: 1 })}
          decreaseScore={() => markScore({ index: 0, value: -1 })} />
        <CardScore
          match={match}
          reload={reload}
          p={p2}
          dependents={p2Dependents}
          win={winner?.playerId === p2?.playerId}
          matching={matching}
          plusScore={() => markScore({ index: 1, value: 1 })}
          decreaseScore={() => markScore({ index: 1, value: -1 })} />
      </div>

      <div className="absolute h-6 w-full z-20 top-5 bg-background"></div>

      {showButtons && (
        <div className={cn('h-[40px] w-[200px] rounded-b-[5px]',
          "transition-all flex items-center justify-center px-2 absolute z-10",
          "group-hover:-translate-y-[4px] -translate-y-[45px]",
          "phone:-translate-y-[4px]"
        )}>

          {matching && (
            <Modal
              title={"Scoreboard"}
              triggerButton={(
                <Tooltip label="Settings" side="left" >
                  <Button size="icon" className="w-6 h-6 mx-1">
                    <Settings className="h-4 w-4" />
                  </Button>
                </Tooltip>
              )}
              render={({ close }) => {
                return (
                  <MatchSettings
                    hiddenDelete={true}
                    disabledChangePlayer={true}
                    match={match}
                    mutate={async () => {
                      await reload()
                      close()
                    }}
                  />
                )
              }}
            />
          )}


          <Button size="icon" className="w-6 h-6 mx-1" onClick={() => setOpenScoreboard(true)}>
            <MoveDiagonal className="h-4 w-4" />
          </Button>

          {matching && match.stage !== "finished" && (
            <Button onClick={() => resetScore()} size="icon" className="w-6 h-6 mx-1">
              <Eraser className="h-4 w-4" />
            </Button>
          )}

          {matching && match.stage !== "finished" && (
            <Tooltip label="Finished" side="right" >
              <Button onClick={() => finishMatch()} size="icon" className="w-6 h-6 mx-1">
                <Check className="h-4 w-4" />
              </Button>
            </Tooltip>
          )}
          {matching && match.stage === "finished" && (
            <Tooltip label="Unfinished" side="right" >
              <Button onClick={() => unfinished()} size="icon" className="w-6 h-6 mx-1">
                <RotateCw className="h-4 w-4" />
              </Button>
            </Tooltip>
          )}
        </div >
      )}

      {openScoreboard && (
        <FixedScoreView
          match={match}
          mutate={reload}
          disabled={!matching}
          close={() => setOpenScoreboard(false)}
        />
      )}
    </div >
  )
}


