// 作为画布基础设施（第三层）
import { useState, forwardRef, useImperativeHandle, useEffect, useRef } from "react"
import { cn } from "@/shadcn/utils"
import { minBy } from "lodash"
import { useScroll } from "react-use"
import { Button } from "@/shadcn/components/ui/button"
import { getWinnerLoserByScore } from "@/models/match"
import { MatchSmallCard } from "./match-small-card"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/shadcn/components/ui/tabs"

const router_right_left = (props) => {
  let { mode, start, end, key, xDistance, spacer, roundSize } = props
  let { yGapCenter1, yGapCenter2 } = props
  let { endTop, startBottom, startTop, endBottom } = props
  let halfSpacer = spacer * 0.5

  let mostBottom = Math.max(
    start.element.y + start.element.h,
    end.element.y + end.element.h
  )
  let mostRight = Math.max(start.x, end.x + end.element.w)
  let mostLeft = Math.min(start.x - start.element.w, end.x) // 因为 start 在节点的最右边，因此要减去节点的宽度

  // 特殊情况（只考虑 start 在 end 右边的情况，因为反之的那种情况不必考虑）
  if (start.x > end.x) {
    let gap1 = endTop - startBottom
    if (end.y > start.y && gap1 < 40) {
      key = `(${props.xk})_(${props.yk})_y_close_${mode}`
    }
    let gap2 = startTop - endBottom
    if (start.y > end.y && gap2 < 40) {
      key = `(${props.xk})_(${props.yk})_y_close_${mode}`
    }
  }
  let mapper = {
    "(end.x_>_start.x)_(end.y_>_start.y)_straight": [
      `L${start.x + xDistance / 2} ${start.y}`,
      `L${start.x + xDistance / 2} ${end.y}`,
    ],
    "(end.x_>_start.x)_(start.y_>_end.y)_straight": [
      `L${start.x + xDistance / 2} ${start.y}`,
      `L${start.x + xDistance / 2} ${end.y}`,
    ],
    "(start.x_>_end.x)_(start.y_>_end.y)_straight": [
      `L${start.x + spacer} ${start.y}`,
      `L${start.x + spacer} ${yGapCenter2}`,
      `L${end.x - spacer} ${yGapCenter2}`,
      `L${end.x - spacer} ${end.y}`,
    ],
    "(start.x_>_end.x)_(end.y_>_start.y)_straight": [
      `L${start.x + spacer} ${start.y}`,
      `L${start.x + spacer} ${yGapCenter1}`,
      `L${end.x - spacer} ${yGapCenter1}`,
      `L${end.x - spacer} ${end.y}`,
    ],
    "(start.x_>_end.x)_(end.y_>_start.y)_y_close_straight": [
      `L${mostRight + spacer} ${start.y}`,
      `L${mostRight + spacer} ${mostBottom + spacer}`,
      `L${end.x - spacer} ${mostBottom + spacer}`,
      `L${end.x - spacer} ${end.y}`,
    ],
    "(start.x_>_end.x)_(start.y_>_end.y)_y_close_straight": [
      `L${start.x + spacer} ${start.y}`,
      `L${start.x + spacer} ${mostBottom + spacer}`,
      `L${mostLeft - spacer} ${mostBottom + spacer}`,
      `L${mostLeft - spacer} ${end.y}`,
    ],

    "(end.x_>_start.x)_(end.y_>_start.y)_round": [
      genRoundPath("right-bottom", { H: start.x + xDistance / 2 }, roundSize),
      genRoundPath("bottom-right", { V: end.y - roundSize }, roundSize),
    ],
    "(end.x_>_start.x)_(start.y_>_end.y)_round": [
      genRoundPath("right-top", { H: start.x + xDistance / 2 }, roundSize),
      genRoundPath("top-right", { V: end.y + roundSize }, roundSize),
    ],
    "(start.x_>_end.x)_(start.y_>_end.y)_round": [
      genRoundPath("right-top", { H: start.x + spacer }),
      genRoundPath("top-left", { V: yGapCenter2 + spacer }), // 这里要取空隙的一半位置
      genRoundPath("left-top", { H: end.x - spacer }),
      genRoundPath("top-right", { V: end.y + halfSpacer }),
    ],
    "(start.x_>_end.x)_(end.y_>_start.y)_round": [
      genRoundPath("right-bottom", { H: start.x + spacer }),
      genRoundPath("bottom-left", { V: yGapCenter1 - halfSpacer }), // 这里要取空隙的一半位置
      genRoundPath("left-bottom", { H: end.x - spacer }),
      genRoundPath("bottom-right", { V: end.y - halfSpacer }),
    ],
    "(start.x_>_end.x)_(end.y_>_start.y)_y_close_round": [
      genRoundPath("right-bottom", { H: mostRight + spacer }),
      genRoundPath("bottom-left", { V: mostBottom + halfSpacer }),
      genRoundPath("left-top", { H: end.x - spacer }),
      genRoundPath("top-right", { V: end.y + halfSpacer }),
    ],
    "(start.x_>_end.x)_(start.y_>_end.y)_y_close_round": [
      genRoundPath("right-bottom", { H: start.x + spacer }),
      genRoundPath("bottom-left", { V: mostBottom + halfSpacer }),
      genRoundPath("left-top", { H: mostLeft - spacer }),
      genRoundPath("top-right", { V: end.y + halfSpacer }),
    ],
  }

  return mapper[key]
}

const genRoundPath = (direction, { V, H }, rs = 20) => {
  // 按如下标记
  //  M 起点
  //  VH 表示横向或者竖直方向的直线
  //  A 表示弧线 (A x半径(20),y半径(20) x旋转度数(0) 优弧劣弧(01) 弧线方向(01) x半径(20) y半径(20))
  // `M0,0 V${end.y - rs} a${rs},${rs} 0 0 0 ${rs},${rs} H${end.x}`
  // 一个直角的圆弧可以被总结成如下的 8 种情形：
  let mapper = {
    "bottom-right": `V${V} a${rs},${rs} 0 0 0 ${rs},${rs}`,
    "bottom-left": `V${V} a${rs},${rs} 0 0 1 ${-rs},${rs}`,
    "top-right": `V${V} a${rs},${rs} 0 0 1 ${rs},${-rs}`,
    "top-left": `V${V - rs} a${rs},${rs} 0 0 0 ${-rs},${-rs}`,
    "left-top": `H${H + rs} a${rs},${rs} 0 0 1 ${-rs},${-rs}`,
    "left-bottom": `H${H + rs} a${rs},${rs} 0 0 0 ${-rs},${rs}`,
    "right-top": `H${H - rs} a${rs},${rs} 0 0 0 ${rs},${-rs}`,
    "right-bottom": `H${H - rs} a${rs},${rs} 0 0 1 ${rs},${rs}`,
  }
  return mapper[direction]
}

const getPortDirection = (port) => {
  if (typeof port === "string") {
    return port
  }
  if (port === undefined) {
    // 没有端点，因为是手动连线
    return ""
  }
  let { x, y } = port

  // 这里可能存在优先级冲突问题，先暂时不考虑，因为自己还没决定好如何呈现在 4 个角上的拐弯方式
  if (x === "left" || x === "right") {
    return x
  }
  if (y === "top" || y === "bottom") {
    return y
  }
}

const routerMap = ({ start, end, mode }) => {
  let props = { start, end, mode }
  // let mode = "round" // 'round' | 'straight'
  // let round = 20 // 转弯圆角大小（基础值）
  props.round = 5
  props.spacer = 40 // 呼吸空间，通常预留这个空间来作为转弯或间隔
  props.halfSpacer = props.spacer * 0.5

  // 应该放弃 abs 吗？
  let xDistance = Math.abs(start.x - end.x)
  let yDistance = Math.abs(start.y - end.y)
  props.xRoundSize = xDistance / 2 > props.round ? props.round : xDistance / 2
  props.yRoundSize = yDistance / 2 > props.round ? props.round : yDistance / 2
  props.xDistance = xDistance
  props.yDistance = yDistance
  props.roundSize = Math.min(props.xRoundSize, props.yRoundSize) // 时机运用的转弯圆角大小，以 20 为最大值，有可能缩小

  props.xk = end.x >= start.x ? "end.x_>_start.x" : "start.x_>_end.x"
  props.yk = end.y >= start.y ? "end.y_>_start.y" : "start.y_>_end.y"
  props.key = `(${props.xk})_(${props.yk})_${mode}`

  // element 的 xy 指的是左上角，因此不必考虑落点的比例问题
  props.startTop = start.element.y
  props.startBottom = start.element.y + start.element.h
  props.endTop = end.element.y
  props.endBottom = end.element.y + end.element.h
  props.yGapCenter1 = (props.endTop + props.startBottom) / 2
  props.yGapCenter2 = (props.startTop + props.endBottom) / 2

  props.startRight = start.element.x + start.element.w
  props.startLeft = start.element.x
  props.endRight = end.element.x + end.element.w
  props.endLeft = end.element.x
  props.xGapCenter1 = (props.endLeft + props.startRight) / 2
  props.xGapCenter2 = (props.startLeft + props.endRight) / 2

  // 分流到 16 个函数里单独处理此事，统一用 props 将所有参数传下去
  let portKey = `${getPortDirection(start.port)}_${getPortDirection(end.port)}`
  switch (portKey) {
    case "right_left":
      return router_right_left(props)
    default:
      // console.log("这个方向功能暂未实现:", portKey)
      return []
  }
}

// 生成直线
const genLine = (start, end) => {
  return `M ${start.x} ${start.y} L ${end.x} ${end.y}`
}

// 生成曲线，根据两个坐标点，生成一条 svg 的曲线 path
const genPath = (start, end) => {
  // let distance = Math.sqrt(
  //   Math.pow(lineEnd.x - lineStart.x, 2) +
  //     Math.pow(lineEnd.y - lineStart.y, 2)
  // )
  let lineStart = start
  let lineEnd = end
  let distanceX = lineEnd.x - lineStart.x
  // let distanceY = Math.abs(lineEnd.y - lineStart.y)
  let level = distanceX < 0 ? 75 : distanceX * 0.8
  lineStart.cut = lineStart.x + level
  lineEnd.cut = lineEnd.x - level
  return `M ${start.x} ${start.y} C ${start.cut} ${start.y} ${end.cut} ${end.y} ${end.x} ${end.y}`
}

// 生成直角路径（可选 mode 为圆角）
const genRightAnglePath = (start, end, mode) => {
  let pathArray = []
  // 画笔先移动到起点
  pathArray.push(`M${start.x} ${start.y}`) // 起点总是固定的
  // 距离过近的防御
  // if (xDistance < 140 || yDistance < 140) {
  //   pathArray.push(`L${end.x} ${end.y}`) // 提前结束，因为可能出错
  //   return pathArray.join(" ")
  // }
  // 原因是 start.port 原本是 'right',
  // 现在是对象，所以要用 start.port.x === 'right'
  pathArray.push(routerMap({ start, end, mode: mode }))
  pathArray.push(`L${end.x} ${end.y}`) // 终点总是固定的
  return pathArray.join(" ")
}

// 将 port 关键字[字符串] 映射为坐标数组
const portMapping = (key) => {
  let map = {
    center: { x: "center", y: "center" },
    top: { x: "center", y: "top" },
    bottom: { x: "center", y: "bottom" },
    left: { x: "left", y: "center" },
    right: { x: "right", y: "center" },
    "top-left": { x: "left", y: "top" },
    "top-right": { x: "right", y: "top" },
    "bottom-left": { x: "left", y: "bottom" },
    "bottom-right": { x: "right", y: "bottom" },
  }
  if (map[key]) {
    return map[key]
  }
  return key
}

const getPositionByKeywords = (portKeywords, view) => {
  let { position, size } = view
  let map = {
    x_center: { x: position.x + size.w / 2 },
    x_left: { x: position.x },
    x_right: { x: position.x + size.w },

    y_center: { y: position.y + size.h / 2 },
    y_top: { y: position.y },
    y_bottom: { y: position.y + size.h },
  }
  let result = {
    x: position.x + portKeywords.x,
    y: position.y + portKeywords.y, // 如果是常量，那么这里直接赋值
    ...map[`x_${portKeywords.x}`],
    ...map[`y_${portKeywords.y}`], // 如果是关键字，那么在后面这段被替换掉
  }
  return result
}

const getPositionByDot = (dot) => {
  // port: 方位常量或 {x, y} 对象，xy 也可以是方位常量
  let { port, element } = dot
  let portKeywords = port
  if (typeof port === "string") {
    portKeywords = portMapping(port) // 字符串的话转换为 kws 对象
  }
  if (element?.view) {
    return {
      port: port,
      element: {
        x: element.view.position.x,
        y: element.view.position.y,
        w: element.view.size.w,
        h: element.view.size.h,
      },
      ...getPositionByKeywords(portKeywords, element.view),
    }
  }
  // 暂不考虑没有 view 的情况，虽然这个情况在 connect 的时候肯定会出现
  return {
    x: dot.x,
    y: dot.y,
    port: port,
  }
}

const genLinkLineContainer = (from, to, mode = "rightAngle") => {
  // 出一个绘制 svg 所需的数据
  let start = getPositionByDot(from)
  let end = getPositionByDot(to)

  let se = start?.element || { x: 0, y: 0, w: 0, h: 0 }
  let ee = end?.element || { x: 0, y: 0, w: 0, h: 0 }
  let padding = 60

  let container = {
    x: Math.min(se.x, ee.x) - padding,
    y: Math.min(se.y, ee.y) - padding,
  }
  container.w = Math.max(se.x + se.w, ee.x + ee.w) - container.x + padding
  container.h = Math.max(se.y + se.h, ee.y + ee.h) - container.y + padding

  // 将 canvas 坐标转换为相对于 container （SVG容器）的坐标
  // 既然是转换，那就从 start end 的 xy 直接减去 container 的坐标数值
  let lineStart = {
    x: start.x - container.x,
    y: start.y - container.y,
    element: {
      x: se.x - container.x,
      y: se.y - container.y,
      w: se.w,
      h: se.h,
    },
    port: from.port,
  }
  let lineEnd = {
    x: end.x - container.x,
    y: end.y - container.y,
    element: {
      x: ee.x - container.x,
      y: ee.y - container.y,
      w: ee.w,
      h: ee.h,
    },

    port: to.port,
  }

  // gen lines
  let dPath = ""
  let rightAnglePath = ""

  if (mode === "line") {
    dPath = genLine(lineStart, lineEnd)
    rightAnglePath = genLine(lineStart, lineEnd)
  } else if (mode === "curve") {
    dPath = genPath(lineStart, lineEnd)
    rightAnglePath = genPath(lineStart, lineEnd)
  } else if (mode === "rightAngle") {
    // dPath = genRightAnglePath(lineStart, lineEnd, "round")
    rightAnglePath = genRightAnglePath(lineStart, lineEnd, "straight")
    // rightAnglePath = genRightAnglePath(lineStart, lineEnd, "round")
  }

  return {
    // container 坐标参考系为 canvas
    x: container.x,
    y: container.y,
    width: container.w,
    height: container.h,

    // line 坐标参考系为 container
    lineStart,
    lineEnd,
    dPath,
    rightAnglePath,
  }
}

class ElementModel {
  constructor(json, bus) {
    this.bus = bus
    this.key = json.key
    this.view = json.view || {}
    this.render = json.render
    this.output = []
    this.input = []
    this.asFromSideLink = []
    this.asToSideLink = []

    for (let i in json) {
      this[i] = json[i]
    }
  }

  // 连接一个节点
  connect = (element) => {
    this.output.push(element)
    element.input.push(this)
  }
}

class LinkLine {
  constructor(json, bus) {
    this.bus = bus
    this.from = json?.from
    this.to = json?.to
    // from, to 的结构有两种：
    // 1. { key: element.key, port: "top", element: element }
    // 2. { x: 100, y: 100 }
  }

  refreshLine = () => {
    let { from, to } = this

    // new
    let line = genLinkLineContainer(from, to, "rightAngle") // line, curve, rightAngle

    if (
      isNaN(line.x) ||
      isNaN(line.y) ||
      isNaN(line.width) ||
      isNaN(line.height)
    ) {
      return null
    }
    return line
  }
}

class DraggableBoardDataset {
  constructor(raw) {
    this.rawNodes = raw.nodes // 原始数据的引用，不允许任何修改
    this.rawLinks = raw.links // 原始数据的引用，不允许任何修改
    this.snapshot = { nodes: [], links: [] } // 当前快照，可以被修改
    this.actionStack = [] // 快照栈，保存本次网页会话的操作快照
    this.undoStack = []
    this.dataPipe()
  }

  dataPipe = () => {
    // 扫两遍，第一遍创建类，并拷贝对象
    let nodes = this.rawNodes.map((i) => {
      let node = new ElementModel(i, this)
      return node
    })
    let links = this.rawLinks.map((i) => {
      let node = new LinkLine(i, this)
      return node
    })
    this.snapshot = { nodes, links }
    this.initialBindElementsToLinks()
  }

  initialBindElementsToLinks = () => {
    let { nodes, links } = this.snapshot
    let newLinks = links.map((i) => {
      // Todo 未来连线不必依赖于元素
      let fromElement = nodes.find((k) => k.key === i.from.key)
      let toElement = nodes.find((k) => k.key === i.to.key)

      i.from = {
        ...i.from,
        element: fromElement,
      }
      i.to = {
        ...i.to,
        element: toElement,
      }
      if (fromElement && toElement) {
        fromElement.asFromSideLink.push(i)
        toElement.asToSideLink.push(i)
        fromElement.connect(toElement)
      }
      return i
    })
    this.snapshot.links = newLinks.filter((i) => i !== null)
  }

  // 传入一个坐标，寻找当前坐标内符合条件的元素
  findElementByCoordinate = ({ x, y }) => {
    let { nodes } = this.snapshot
    let element = nodes.find((i) => {
      let { x: elementX, y: elementY } = i.view.position
      let { w, h } = i.view.size
      return (
        x >= elementX && x <= elementX + w && y >= elementY && y <= elementY + h
      )
    })
    return element
  }


  undo = () => {
    let action = this.actionStack.pop()
    // console.log("读快照，此时数据正确", action.nodes[1].view.position)
    let last = this.actionStack[this.actionStack.length - 1]
    if (action) {
      this.undoStack.push(action)
      this.snapshot = last
    }
    // console.log("undo, check undoStack", this.undoStack)
  }

  redo = () => {
    let action = this.undoStack.pop()
    // console.log("读快照，此时数据正确", action.nodes[1].view.position)
    if (action) {
      this.actionStack.push(action)
      this.snapshot = action
    }
  }
}

const spacer = (direction, number) => {
  if (direction === "x") {
    return number * 290
  } else if (direction === "y") {
    return number * 100
  }
}

// export const matchesToDataset = (matches, nodeRender) => {
//   if (!matches) { return }
//   let nodes = []
//   let links = []
//   matches.forEach(m => {
//     let x = spacer("x", m.x)
//     let y = spacer("y", m.y)
//     nodes.push({
//       name: 'match',
//       key: m.id,
//       view: { position: { x: x + 50, y: y + 50 }, size: { w: 200, h: 62 } },
//       render: nodeRender,
//     })

//     if (m?.linkIds?.length > 0) {
//       m?.linkIds.forEach(linkId => {
//         links.push({
//           from: { key: m.id, port: "right" },
//           to: { key: linkId, port: "left" },
//         })
//       })
//     }
//   })

//   let groupedMatches = matches.reduce((acc, match) => {
//     let { round, group } = match.tournamentProperties

//     if (!acc[round]) {
//       acc[round] = {}
//     }

//     if (!acc[round][group]) {
//       acc[round][group] = []
//     }

//     acc[round][group].push(match)

//     return acc
//   }, {})

//   let markPoints = Object.entries(groupedMatches).map(([index, value]) => {
//     // console.log("entries", k)
//     let { win, lose } = value
//     let result = []
//     let offsetX = 50
//     let offsetY = 10

//     if (lose) {
//       let firstWin = minBy(win, (i) => i.tournamentProperties.number)
//       result.push({
//         name: "mark",
//         key: `wg-${index}`,
//         render: () => <div className="text-center">{`胜者组第 ${index} 轮`}</div>,
//         view: {
//           position: { x: spacer("x", firstWin.x) + offsetX, y: spacer("y", firstWin.y) + offsetY },
//           size: { w: 200, h: 30 }
//         }
//       })

//       let firstLose = minBy(lose, (i) => i.tournamentProperties.number)
//       result.push({
//         key: `lg-${index}`,
//         render: () => <div className="text-center">{`败者组第 ${index} 轮`}</div>,
//         view: {
//           position: { x: spacer("x", firstLose.x) + offsetX, y: spacer("y", firstLose.y) + offsetY },
//           size: { w: 200, h: 30 }
//         }
//       })
//     } else {
//       let firstWin = minBy(win, (i) => i.tournamentProperties.number)
//       result.push({
//         key: `nil-g-${index}`,
//         render: () => <div className="text-center">{`第 ${index} 轮`}</div>,
//         view: {
//           position: { x: spacer("x", firstWin.x) + offsetX, y: spacer("y", firstWin.y) + offsetY },
//           size: { w: 200, h: 30 }
//         }
//       })
//     }
//     return result
//   }).flat(Infinity)

//   nodes.push(...markPoints)

//   return new DraggableBoardDataset({ nodes, links })
// }

const Element = (props) => {
  let { view } = props.element

  let initialPosition = view.position ? view.position : { x: 0, y: 0, z: 1 }
  let [position] = useState(initialPosition)
  let initialSize = view.size ? view.size : { w: 0, h: 0 }
  let [size] = useState(initialSize)

  return (
    <div>
      <div
        aria-hidden="true"
        role="textbox"
        tabIndex={0}
        data-connectable={"true"}
        className={cn(`absolute rounded-lg`)}
        style={{
          width: size.w || "auto",
          height: size.h || "auto",
          left: position.x,
          top: position.y,
          zIndex: position.z,
        }}
      >
        <div className="node-event-wall w-full h-full">
          {props.render(props.element)}
        </div>
      </div>
    </div>
  )
}

const ConnectPath = (props) => {
  let [line, setLine] = useState(props.link.refreshLine())
  useEffect(() => {
    props.link.reloadRenderLines = () => {
      setLine(props.link.refreshLine())
    }
  }, [props.link])

  if (!line) { return <></> }

  return (
    <svg
      className="pointer-events-none absolute overflow-visible z-0"
      style={{
        left: line.x,
        top: line.y,
        height: line.height,
        width: line.width,
      }}
    >
      <g
        className="cursor-pointer connect-group"
        focusable="true"
        style={{ pointerEvents: "visibleStroke" }}
      >
        <path
          d={line.rightAnglePath}
          className={cn(`connect-path stroke-[4px] stroke-secondary fill-none`, props.classNames)}
          style={{ strokeLinecap: "round", strokeLinejoin: "round" }}
        />
        {/* <path
          d={line.dPath}
          className={cn(`connect-path stroke-[4px] stroke-secondary fill-none`, props.classNames)}
          style={{ strokeLinecap: "round", strokeLinejoin: "round" }}
        /> */}
      </g>
    </svg>
  )
}

export const PegBoard = forwardRef((props, ref) => {
  const scrollRef = useRef(null)
  const [pointerDown, setPointerDown] = useState(false)
  const { x, y } = useScroll(scrollRef)
  let matches = props.event?.matchController?.matches
  let [links, setLinks] = useState(props.dataset?.snapshot?.links || [])
  let [nodes, setNodes] = useState(props.dataset?.snapshot?.nodes || [])

  useImperativeHandle(
    ref,
    () => ({
      reload: () => {
        setNodes([...props.dataset.snapshot.nodes])
        setLinks([...props.dataset.snapshot.links])
      },
    }),
    [props.dataset]
  )

  let { w, h } = props.containerSize
  let cw = scrollRef.current?.clientWidth
  let ch = scrollRef.current?.clientHeight
  let allowScroll = cw < w || ch < h
  return (
    <Tabs defaultValue="tournament">
      <TabsList className="grid w-full grid-cols-2">
        <TabsTrigger value="tournament">Tournament</TabsTrigger>
        <TabsTrigger value="list">List</TabsTrigger>
      </TabsList>
      <TabsContent value="tournament">

        <div
          ref={scrollRef}
          onPointerLeave={() => setPointerDown(false)}
          onPointerDown={() => setPointerDown(true)}
          onPointerUp={() => setPointerDown(false)}
          onPointerMove={(e) => {
            if (pointerDown === true) {
              scrollRef.current.scrollTo(x - e.movementX, y - e.movementY)
            }
          }}
          className={cn("overflow-scroll relative h-full w-full rounded-lg scrollbars-hidden bg-background",
            {
              "cursor-grabbing": allowScroll && pointerDown,
              "cursor-grab": allowScroll && !pointerDown,
              "min-h-[calc(100dvh_-_6rem)]": props.screenFull,
            }
          )}
        >
          <div style={{ width: `${w}px`, height: `${h}px` }}>
            {nodes.map((i, index) => {
              return (
                <Element
                  key={`nodes_${index}_${i.key}`}
                  element={i}
                  render={(element) => {
                    return <>{i?.render?.(i)}</>
                  }}
                />
              )
            })}
            {links.map((i, index) => {
              return (
                <ConnectPath
                  key={`lines_${index}_${i.from.key}_${i.to.key}`}
                  link={i}
                />
              )
            })}
          </div>
        </div>

      </TabsContent>
      <TabsContent value="list">
        <div className='flex px-4 py-12 flex-wrap gap-12 justify-center'>
          {matches.map(i => {
            return (
              <div key={i.id}
                data-match-id={i.id}
                className={cn("rounded-sm relative")}
              >
                <MatchSmallCard
                  stage={props.event?.stage}
                  match={i}
                  reload={props.reload}
                />
              </div>
            )
          })}
        </div>
      </TabsContent>
    </Tabs>
  )
})


export const useMatchesDataset = (event, matches, nodeRender) => {
  if (!event) { return [] }
  if (!matches) { return [] }
  let rule = event.ruleTemplateKey
  let nodes = []
  let links = []

  // 淘汰赛，读取后端给的 xy 渲染位置
  if (rule === 'tournament-1' || rule === 'tournament-2') {
    matches.forEach(m => {
      let x = spacer("x", m.x)
      let y = spacer("y", m.y)
      nodes.push({
        name: 'match',
        key: m.id,
        view: { position: { x: x + 50, y: y + 50 }, size: { w: 200, h: 62 } },
        render: nodeRender,
      })

      if (m?.linkIds?.length > 0) {
        m?.linkIds.forEach(linkId => {
          links.push({
            from: { key: m.id, port: "right" },
            to: { key: linkId, port: "left" },
          })
        })
      }
    })

    let winnerGroupLast = matches[matches.length - 2]

    if (event.ruleTemplateKey === "tournament-2" && winnerGroupLast?.scoreboard) {
      // 没完赛或1p 胜过 2p 时，隐藏 Reset
      let { winner } = getWinnerLoserByScore(winnerGroupLast.scoreboard)
      let noWinner = winnerGroupLast.stage === "in progress" || winner === null
      let P1WinP2 = winner?.playerId === winnerGroupLast?.scoreboard[0]?.playerId
      if (noWinner || P1WinP2) {
        nodes[nodes.length - 1].render = () => {
          return (
            <div className="w-[200px] h-[60px] rounded-[5px] p-2 text-sm border flex items-center justify-center">
              <span className="opacity-80">
                {noWinner && "胜者决赛未结束，无 Reset"}
                {P1WinP2 && "胜者冠军已获胜，无 Reset"}
              </span>
            </div>
          )
        }
      }
    }

    let groupedMatches = matches.reduce((acc, match) => {
      let { round, group } = match.tournamentProperties

      if (!acc[round]) {
        acc[round] = {}
      }

      if (!acc[round][group]) {
        acc[round][group] = []
      }

      acc[round][group].push(match)

      return acc
    }, {})

    let roundAndGroupLabel = Object.entries(groupedMatches).map(([index, value]) => {
      // console.log("entries", k)
      let { win, lose } = value
      let result = []
      let offsetX = 50
      let offsetY = 10

      if (lose) {
        let firstWin = minBy(win, (i) => i.y)
        if (firstWin) {
          result.push({
            name: "mark",
            key: `wg-${index}`,
            render: () => <div className="text-center">{`胜者组第 ${index} 轮`}</div>,
            view: {
              position: { x: spacer("x", firstWin.x) + offsetX, y: spacer("y", firstWin.y) + offsetY },
              size: { w: 200, h: 30 }
            }
          })
        }

        let firstLose = minBy(lose, (i) => i.y)
        result.push({
          key: `lg-${index}`,
          render: () => <div className="text-center">{`败者组第 ${index} 轮`}</div>,
          view: {
            position: { x: spacer("x", firstLose.x) + offsetX, y: spacer("y", firstLose.y) + offsetY },
            size: { w: 200, h: 30 }
          }
        })
      } else {
        let firstWin = minBy(win, (i) => i.y)
        result.push({
          key: `nil-g-${index}`,
          render: () => <div className="text-center">{`第 ${index} 轮`}</div>,
          view: {
            position: { x: spacer("x", firstWin.x) + offsetX, y: spacer("y", firstWin.y) + offsetY },
            size: { w: 200, h: 30 }
          }
        })
      }
      return result
    }).flat(Infinity)

    nodes.push(...roundAndGroupLabel)
    return new DraggableBoardDataset({ nodes, links })
  }

  // 瑞士轮，后端不给 xy，前端渲染
  if (rule === "swiss-1") {
    // 012345789
    // 001122334
    matches.forEach((m, index) => {
      let col = index % 2
      let x = spacer("x", col) // 010101...
      let y = spacer("y", Math.floor(index / 2)) // 001122...
      nodes.push({
        name: 'match',
        key: m.id,
        view: { position: { x: x + 50, y: y + 50 }, size: { w: 200, h: 62 } },
        render: nodeRender,
      })
    })

    let roundAndGroupLabel = {
      key: `swiss-round-1`,
      render: () => (
        <div className="bg-gray-600 h-8 flex items-center justify-center">
          {`第 1 轮`}
        </div>
      ),
      view: {
        position: { x: 0, y: 10 },
        size: { w: 1012, h: 32 }
      }
    }
    nodes.push(roundAndGroupLabel)
    let roundActionButton = {
      key: `swiss-round-action`,
      render: () => (
        <div className="">
          <Button onClick={() => { }}>生成下一轮</Button>
        </div>
      ),
      view: {
        position: { x: 0, y: 250 },
        size: { w: 200, h: 30 }
      }
    }
    nodes.push(roundActionButton)

    return new DraggableBoardDataset({ nodes, links })
  }

}
