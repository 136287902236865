export const userAgreement = {
  title: "用户协议",
  key: "user-agreement",
  to: "/docs/user-agreement",
  date: "2024-04-22",
  body: `
# 用户协议

## 摘要
为方便阅读，此处提供协议的摘要，仅供参考，具体内容以正文为准。

1. 法律法规优先级高于本协议，在使用正赛时，不得以任何形式违反法律法规。
1. 协议、软件有可能随时更新，如您不同意协议中任何条款，请停止使用本服务。
2. 正赛拥有产品的所有权和相关知识产权，包括专利权、商标权、软件著作权等。
3. 您需要对个人账户信息的真实性负责，不得侵犯他人权益、不得违背公序良俗。
4. 在您主动删除账户后，正赛有权删除您的账户信息，您无法找回。
5. 未经授权，您不可使用插件、外挂、破解等方式影响正赛系统的正常运行，不可频繁批量调用接口。
6. 不可抗力导致的服务中断，包括云服务商故障、自然灾害、战争等，正赛不承担责任。

---

## 正文

正赛是一款用于组织赛事和记录比赛分数软件，所有功能的目的旨在于帮助主办方、组织者、参赛选手、观众更好的了解赛事进程。为使用正赛软件（以下简称“正赛”）及服务，你应当阅读并遵守本协议，请您务必审慎阅读、充分理解各条款内容。

除非您已阅读并接受本协议所有条款，否则您无权下载、安装或使用正赛提供相关产品和服务。您的下载、安装、使用、登录等行为即视为您已阅读并同意本协议的约束。

如果您不同意本用户协议中的任何内容，请立即停止使用或访问我们的产品和服务。若您对本协议有任何问题，请发送邮件到 djw7569@gmail.com 与我们联系。


### 一、协议范围和许可范围
本协议是你与正赛之间关于你下载、安装、使用、复制本软件所签订的协议。

1.1 正赛给予你一项个人的，不可转让的许可，以使用本软件。你可以为商业或非商业目的在单一台设备上安装、使用、运行本软件。

1.2 本条及本协议其它条款未明示授权的其它一切权利仍由正赛保留，你在行使这些权利时须另外取得正赛的书面许可。正赛如果未行使前述任何权利，并不构成对该权利的放弃。

### 二、软件安装、卸载与更新
2.1 正赛可能为不同的终端设备开发了不同版本，你应当根据实际情况选择符合的版本安装，也可同时使用多版本。

2.2 如果你不再需要使用本软件或者需要安装新版本，可以自行卸载。

2.3 为改善用户体验、完善服务内容，正赛将不断开发新的服务，并为全体用户提供软件更新（这些更新可能会采取软件替换、修改、功能强化、版本升级等形式）。

### 三、知识产权声明
3.1 正赛依法享有正赛产品的一切合法权属（包括但不限于知识产权及所有权）。正赛的全部著作权及其他一切知识产权以及与正赛相关的所有信息内容，包括但不限于：文字表述及其组合、图标、图饰、图标、色彩组合、界面设计、版面框架、有关数据、印刷材料、电子文档。上述材料等均受中国知识产权相关法律法规和有关的国际条约的保护。

3.2 正赛产品及通过正赛产品提供的视频、游戏、技术软件等产品和信息，除用户自己上传或明确标示由第三方提供的之外，全部权利归属于正赛。

3.3 正赛产品中所体现的产品和服务标识均属于正赛在中国或其他国家、地区的商标或者注册商标，用户未经合法授权不得自行使用。

3.4 正赛在涉及正赛产品及相关各项服务中拥有专利权、专利申请权、商标权、著作权及其他知识产权。正赛并未因为本协议或者因为向用户提供正赛以及相关产品和服务而授予用户任何与正赛相关的知识产权。本协议未明确授予用户的权利均由正赛保留。

### 四、账户

4.1 为完整使用正赛提供的产品和服务，你需要注册正赛提供的账户。用户（你）注册正赛账户时须对所提供信息的真实性、合法性、有效性及完整性负责，并及时维护和更新您的个人信息，以保证信息的真实、合法和有效。
您在正赛中的注册账号所有权及有关权益均归正赛所有，您完成注册手续后仅享有该账号的使用权。您的账号仅限于您本人使用，未经正赛书面同意，禁止以任何形式赠与、借用、出租、转让、售卖或以其他方式许可他人使用该账号。
如果正赛发现或者有合理理由认为使用者并非账号初始注册人，正赛有权暂时或终止向该注册账号提供服务，并有权注销该账号，而无需向注册该账号的用户承担法律责任。
您须自行负责对您的账户和密码进行保密，且须对在您的账户和密码下发生的所有活动（包括但不限于信息披露、信息发布、网上点击同意或提交各类规则协议、网上续签协议或购买服务等）承担责任。
正赛不能也不会对因您未能遵守本条款规定而发生的任何损失负责。若您发现任何人未经授权使用您的账号及密码，您须立即通知正赛。你可以发送邮件至 djw7569@gmail.com 提出您的请求。
您须理解并同意正赛根据您的请求采取行动需要合理时间，对在采取行动前已产生的任何不利后果，正赛不承担任何责任。因其他人恶意攻击或您自身原因或其他不可抗因素而导致账号盗窃、丢失、均由您本人承担责任， 不承担任何责任。

4.2 您可以对账户设置昵称或其他资料，您设置的任何文本、图像不得侵犯或涉嫌侵犯他人的合法权益，且应遵守以下规定：
  - 不以党和国家领导人或其他社会名人的真实姓名、字号、艺名、笔名注册；
  - 不以国家机构或其他机构的名称注册；
  - 不注册不文明、不健康名字，或包含歧视、侮辱、猥亵类词语的账号；
  - 不注册易产生歧义、引起他人误解或其它不符合法律规定的账号。
  - 如您设置的昵称涉嫌侵犯他人合法权益，正赛有权终止向您提供部分或全部软件服务。正赛对此不承担任何责任，您将承担因此产生的任何直接或间接损失及不利后果。

4.3 如果您决定放弃您的正赛账户，您可以执行正赛提供的账号删除程序，我们将删除或匿名化处理您的个人信息。正赛账户一旦被删除将不可恢复，包括官方技术人员也无法访问、恢复被删除账户的数据。因您的账户申请删除对您造成的不利影响，正赛将不承担任何责任。

### 五、使用规范

5.1 用户须对自己的上网行为负责，包括对自己发表、上传或传送的内容（包括文本、图像、音频、视频、软件产品等）负全部责任。用户须承诺在正赛平台上所发布的任何内容均符合中华人民共和国法律、法规、规章以及正赛管理规范的规定。正赛有权对用户发布的任何不符合管理规定的内容予以删除，且不承担任何责任。

5.2 用户承诺在使用正赛产品和服务时应自觉遵守法律法规、社会主义制度、国家利益、公民合法权益、社会公共秩序、道德风尚和信息真实等要求，不发布、转载、传送含有下列内容之一的内容，否则正赛有权自行处理且不通知用户，包括：
- 反对宪法确定的基本原则的；
- 危害国家安全，泄漏国家机密；
- 颠覆国家政权，推翻社会主义制度，煽动分裂国家，破坏国家统一的；
- 损害国家荣誉和利益的；
- 宣扬恐怖主义、极端主义的；
- 宣扬民族仇恨、民族歧视，破坏民族团结的；
- 煽动地域歧视、地域仇恨的；
- 破坏国家宗教政策，宣扬邪教和迷信的；
- 编造、散布谣言、虚假信息，扰乱社会秩序、破坏社会稳定的；
- 散布、传播淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
- 危害网络安全、利用网络从事危害国家安全、荣誉和利益的；
- 侮辱或者诽谤他人，侵害他人合法权益的；
- 对他人进行暴力恐吓、威胁，实施人肉搜索的；
- 涉及他人隐私、个人信息或资料的；
- 散布污言秽语，损害社会公序良俗的；
- 侵犯他人隐私权、名誉权、肖像权、知识产权等合法权益内容的；
- 散布商业广告，或类似的商业招揽信息、过度营销信息及无效信息的；
- 使用本网站常用语言文字以外的其他语言文字评论的；
- 与所评论的信息毫无关系的；
- 所发表的信息毫无意义的，或刻意使用字符组合以逃避技术审核的；
- 侵害未成年人合法权益或者损害未成年人身心健康的；
- 未获他人允许，偷拍、偷录他人，侵害他人合法权利的；
- 包含恐怖、暴力血腥、高危险性、危害表演者自身或他人身心健康内容的，包括但不限于以下情形：
  - 任何暴力和/或自残行为内容；
  - 任何威胁生命健康、利用刀具等危险器械表演的危及自身或他人人身及/或财产权利的内容；
  - 怂恿、诱导他人参与可能会造成人身伤害或导致死亡的危险或违法活动的内容。
- 其他违反法律法规、政策及公序良俗、干扰正赛正常运营或侵犯其他用户或第三方合法权益内容的其他信息。

5.3 用户使用正赛产品与服务时，承诺并遵守以下规定：
- 实施的所有行为均遵守国家法律、法规等规范性文件及正赛各项规则的规定和要求，不违背社会公共利益或公共道德，不损害他人的合法权益，不违反本协议的相关规则。
- 不发布国家禁止的或涉嫌侵犯他人知识产权或其他合法权益的产品或服务信息，不发布违背社会公共利益或公共道德或正赛认为不适合在正赛上发布的产品或服务信息，不发布其他涉嫌违法或违反本协议及各类规则的信息。
- 除用户与正赛另有约定，用户不得对正赛中的任何数据/资料作商业性利用，包括但不限于在未经正赛事先书面同意的情况下，以复制、传播等任何方式使用正赛中展示的所有资料或课程。
- 不使用任何装置、软件或程序干预或试图干预正赛的正常运作或正在正赛平台上进行的任何交易和活动。
- 未经正赛明确的特别书面许可，任何单位或个人不得以任何方式脱离软件设计意图地复制、转载、引用、链接、抓取或以其他方式使用正赛产品内的信息内容，否则，正赛有权追究其法律责任。

5.4 您应对您使用正赛软件及相关服务的行为负责，除非法律允许或者经正赛事先书面许可，您使用正赛软件及相关服务不得具有下列行为：

- 使用未经授权或许可的任何插件、外挂、系统或第三方工具对正赛软件及相关服务的正常运行进行干扰、破坏、修改或施加其他影响。
- 利用或针对正赛软件及相关服务进行任何危害计算机网络安全的行为，包括但不限于：
- 非法侵入网络、干扰网络正常功能、窃取网络数据等危害网络安全的活动；
- 提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；
- 明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助；
- 使用未经许可的数据或进入未经许可的服务器/账号；
- 未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；
- 未经许可，企图探查、扫描、测试正赛系统或网络的弱点或其它实施破坏网络安全的行为；
- 企图干涉、破坏正赛系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；
- 伪造 TCP/IP 数据包名称或部分名称；
- 对正赛软件及相关服务进行反向工程、反向汇编、编译或者以其他方式尝试发现正赛软件及相关服务的源代码；
- 恶意注册正赛账号，包括但不限于频繁、批量注册账号；
- 违反法律法规、本协议、公司的相关规则及侵犯他人合法权益的其他行为。

5.5 如果正赛有理由认为您的行为违反或可能违反上述约定的，正赛可独立进行判断并处理，且在任何时候有权在不事先通知的情况下终止向您提供服务，并依法追究相关责任。

---
如有疑问，请发送邮件到 djw7569@gmail.com 与我们取得联系。

`}
