import { useEffect, useState } from "react"
import { post } from "./laf-db"

export const staticLoad = () => {
  // 从 localStorage 载入基本的静态资源，如果不存在或解析错误，则返回 null
  const lastRequest = localStorage.getItem('app-static-data')
  if (lastRequest) {
    try {
      const staticData = JSON.parse(lastRequest)
      return staticData
    } catch {
      console.log("解析静态资源请求时间失败，将清空缓存")
      localStorage.removeItem('app-static-data')
      return null
    }
  }
  return null
}

export const staticRequireSet = async () => {
  let { data } = await post('/public-read/app-static-data')
  localStorage.setItem('app-static-data', JSON.stringify(data, null, 2))
}

export const initApp = async () => {
  let staticData = staticLoad()
  if (staticData?.date) {
    // 通常上次请求的时间是一个 "2024-05-13T05:36:09.478Z" 这样格式的数据
    // 如果 date 字段与当前时间相比相差 10 分钟以上，就重新请求
    let lastDate = new Date(staticData.date)
    let interval = 1000 * 60 * 10 // 10 分钟
    if (new Date().getTime() - lastDate.getTime() < interval) {
      // console.log("阻断请求")
      return
    }
  }
  staticRequireSet()
}

export let finder = (key, array) => {
  return array.find(i => key === i.key)
}


// 这块逻辑自动允许视图通过 hooks 的方式使用静态数据，并自动刷新、请求
export const useStaticData = () => {
  let [rules, setRules] = useState([])
  let [games, setGames] = useState([])
  useEffect(() => {
    let staticData = staticLoad()
    if (staticData === null) { // 如果数据不存在，尝试请求一次
      staticRequireSet().then(() => {
        let data = staticLoad()
        setGames(data?.games || [])
        setRules(data?.rules || [])
      })
    }
    setGames(staticData?.games || [])
    setRules(staticData?.rules || [])
  }, [])

  return { rules, games }
}