import { Dot, Check, X, HelpCircle } from "lucide-react"
import { Button } from '@/shadcn/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/shadcn/components/ui/form'
import { Input } from '@/shadcn/components/ui/input'
import * as z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from 'react-hook-form'
import { Tooltip } from '@/shadcn/components/_tooltip'
import { Modal } from "@/shadcn/components/_modal"
import { VerifyEmailForm } from "./verify-email-form"
import { toast } from "sonner"

const formSchema = z.object({
  identifier: z.string().optional(),
  email: z.string().email("请输入正确的邮箱地址。"),
  phone: z.string(),
})

export const BasicForm = ({ account, mutate }) => {

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      identifier: account?.identifier || "",
      email: account?.email || "",
      phone: account?.phone || "",
    },
  })

  return (
    <Form {...form} >
      <form className="space-y-8">
        <FormField
          control={form.control}
          name="identifier"
          render={({ field }) => (
            <FormItem>
              <FormLabel >
                <Tooltip label="可通过 Account ID 登录，暂不支持修改">
                  <div className="flex items-center w-fit">
                    <span>Account ID</span>
                    <HelpCircle className="w-4 h-4 ml-1" />
                  </div>
                </Tooltip>
              </FormLabel>
              <FormControl>
                <Input placeholder="" {...field} disabled />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="appleId"
          render={({ field }) => (
            <FormItem>
              <FormLabel >
                <div className="relative">
                  <Tooltip label="如已绑定，可以通过Apple ID 登录">
                    <div className="flex items-center w-fit">
                      <span>Apple ID</span>
                      <HelpCircle className="w-4 h-4 ml-1" />
                    </div>
                  </Tooltip>
                  <div className='absolute -top-4 right-0 '>
                    {account.bindAppleId === true && (
                      <Modal
                        title="取消 Apple ID 绑定"
                        description="确认要解绑这个 AppleID 账户吗？该操作不可逆。"
                        renderTrigger={({ open }) => (
                          <Button
                            variant="link"
                            className="p-0"
                            onClick={(e) => {
                              e.preventDefault()
                              open()
                            }}
                          >
                            取消 Apple ID 绑定
                          </Button>
                        )}
                        render={({ close }) => {
                          return (
                            <Button
                              variant="destructive"
                              className="w-full mt-4 lg:mt-0"
                              onClick={async () => {
                                let result = await account.updateUserProfile("appleId", "")
                                if (result.code === 1) {
                                  toast("Apple ID 解绑成功", { closeButton: true })
                                }
                                await mutate()
                                close()
                              }}
                            >
                              确认，解除绑定
                            </Button>
                          )
                        }}
                      >
                      </Modal>
                    )}
                  </div>
                </div>

              </FormLabel>
              <FormControl>
                <div className="relative">
                  <Input placeholder="" value={account.bindAppleId ? "已绑定" : "尚未绑定"} disabled />
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <div className='relative'>
                  <Tooltip label="经过验证的邮箱地址可以亦可用于登录">
                    <div className="flex items-center w-fit">
                      <span>Email</span>
                      <HelpCircle className="w-4 h-4 ml-1" />
                    </div>
                  </Tooltip>
                  <div className='absolute -top-4 right-0 '>
                    {account.emailVerified ? (
                      <Button variant="link" className="p-0" disabled>
                        <Dot className='text-green-600 dark:text-green-400' />
                        邮箱已验证
                      </Button>
                    ) : (
                      <Modal
                        title="验证邮箱"
                        renderTrigger={({ open }) => (
                          <Button
                            variant="link"
                            className="p-0"
                            onClick={(e) => {
                              e.preventDefault()
                              open()
                            }}
                          >
                            <Dot className='opacity-50' />
                            验证邮箱地址
                          </Button>
                        )}
                        render={({ close }) => {
                          return <VerifyEmailForm account={account} mutate={mutate} close={close} />
                        }}
                      >
                      </Modal>
                    )}
                  </div>

                </div>

              </FormLabel>
              <FormControl>
                <div className='relative'>
                  <Input placeholder="" {...field} />

                  <div className='absolute right-0 top-0 flex items-center'>
                    {field.value !== account.email && (
                      <>
                        <Tooltip label="取消修改">
                          <Button variant="ghost" size="icon" onClick={async (e) => {
                            e.preventDefault()
                            form.setValue("email", account.email)
                          }}>
                            <X className='w-4 h-4' />
                          </Button>
                        </Tooltip>

                        <Tooltip label="修改邮箱地址">
                          <Button variant="ghost" size="icon" onClick={async (e) => {
                            e.preventDefault()
                            let result = await form.trigger()
                            if (!result) { return }
                            let { code, message } = await account.updateUserProfile("email", field.value)
                            if (code === 1) {
                              await mutate()
                            } else {
                              toast.message('修改失败', { description: message })
                            }
                          }}>
                            <Check className='w-4 h-4' />
                          </Button>
                        </Tooltip>
                      </>
                    )}
                  </div>
                </div>

              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* <FormField
          control={form.control}
          name="phone"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Phone</FormLabel>
              <FormControl>
                <Input placeholder="" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        /> */}
      </form>
    </Form >
  )
}