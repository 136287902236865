"use client"
import React, { createContext } from 'react'
import { post } from '@/utils/laf-db'
import { useCurrentUser } from '@/hooks/use-session'

export const AccountContext = createContext()

export function AccountProvider({ children }) {
  let { mutate } = useCurrentUser()
  // 这个上下文主要是存登录和登出的 token

  // 获取当前登录的用户，通过 useCurrentUser 实现
  // 不必检查是否已登录，因为 useCurrentUser 会自动检查，如果没有登录，那么肯定没有对应信息



  // 注册账户
  const register = async ({ identifier, password, displayName }) => {
    let result = await post('/auth/register', { identifier, password, displayName })
    _saveToken(result.data?.access_token)
    await mutate()
    return result
  }
  // 登录
  const login = async (identifier, password) => {
    let result = await post('/auth/login', { identifier: identifier, password })
    _saveToken(result.data?.access_token)
    await mutate()
    return result
  }

  // 登出
  const logout = async () => {
    localStorage.removeItem("access_token")
    localStorage.removeItem("account_data")
    await mutate()
  }

  // 保存登录 token
  const _saveToken = (token) => {
    if (token) {
      localStorage.setItem("access_token", token)
    }
  }

  return (
    <AccountContext.Provider value={{ login, logout, register }}>
      {children}
    </AccountContext.Provider>
  )
}


// static request

// 发送邮箱验证码，准备核验邮箱，这个验证码可以同时用作注册或重置密码
export const sendEmailVerifyCode = async (email) => {
  localStorage.removeItem("account_data")
  return await post('/auth/send-email-code', {
    userEmail: email,
  })
}

// 通过邮件验证码重置密码
export const resetPasswordByEmail = async ({ email, code, newPassword }) => {
  localStorage.removeItem("account_data")
  return await post('/auth/reset-password-by-email', {
    userEmail: email,
    code: code,
    newPassword,
  })
}

