import { useContext } from 'react'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/shadcn/components/ui/form'
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { AccountContext } from '@/components/laf-auth/account'
import * as z from "zod"
import { Button } from '@/shadcn/components/ui/button'
import { Input } from '@/shadcn/components/ui/input'
import { PasswordInput } from '@/shadcn/components/_password-input'
import { toast } from 'sonner'

const formSchema = z.object({
  identifier: z.string().min(2, { message: "ID must be at least 2 characters." }),
  password: z.string().min(2, { message: "Password must be at least 2 characters." }),
  confirmPassword: z.string().min(2, { message: "Password must be at least 2 characters." }),
  displayName: z.string().min(2, { message: "Displayname must be at least 2 characters." }),
}).refine((data) => data.password === data.confirmPassword, {
  message: "Passwords don't match",
  path: ["confirmPassword"], // path of error
})
export function RegisterByPasswordForm({ callback }) {
  const { register } = useContext(AccountContext)

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      identifier: "",
      password: "",
      confirmPassword: "",
      displayName: "",
    },
  })

  const onSubmit = async (values) => {
    let { identifier, password, displayName } = values
    let { code, message } = await register({ identifier, password, displayName })
    if (code === 1) {
      toast("Register & Login Successful", { closeButton: true })
      callback()
    } else {
      toast.message('注册失败', {
        description: message,
      })

    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-8 bg-secondary p-4 rounded-lg min-h-80">
        <FormField
          control={form.control}
          name="identifier"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input placeholder="ID" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <PasswordInput placeholder="密码" autoComplete="new-password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="confirmPassword"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <PasswordInput placeholder="确认密码" {...field} autoComplete="new-password" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="displayName"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input placeholder="显示名称（可修改，其他选手会看到这个名称）" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit" className="w-full mt-auto">注册并登录</Button>
      </form>
    </Form>
  )
}
