import ReactMarkdown from 'react-markdown'
import { useParams } from 'react-router-dom'
import { changelog } from './changelog'
import { whyNeedLogin } from './why-need-login'
import { userAgreement } from './user-agreement'
import { privacyPolicy } from './privacy-policy'


export const docs = [
  changelog,
  whyNeedLogin,
  userAgreement,
  privacyPolicy,
]

const useDocsMarkdown = (slug) => {
  let doc = docs.find(i => i.key === slug)
  return doc?.body ? doc?.body : 'Not Found'
}


export const PostPage = () => {
  const params = useParams()
  const markdown = useDocsMarkdown(params.slug)
  return (
    <article className="prose prose-zinc mx-auto pt-12 pb-12 px-6 md:pt-24 dark:prose-invert">
      <ReactMarkdown>
        {markdown}
      </ReactMarkdown>
    </article>
  )
}
