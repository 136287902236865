export const changelog = {
  title: "更新日志",
  key: "changelog",
  to: "/docs/changelog",
  date: "2024-04-01",
  body: `
# Changelog
Latest updates and announcements.


## 2024 年 8 月更新，大版本升级与赛事管理功能强化
- 新增瑞士轮规则，针对不同人数的比赛，自动调整比赛轮次，瑞士轮使得选手有更多参与机会、降低了运气成份，同时所有选手都将得到准确排名。
- 新增自主报名，如果您的比赛是公开赛事，那么您可以获得一个链接，让选手自行报名，您可以随时查看报名情况。
- 新增锦标赛的多视图显示，现在组织者有更多方式聚焦比赛。
- 调整了淘汰赛中一部分功能锁定，现在一定程度上主办方可以调整选手座位，也可以直接打乱全部顺序随机分配座位。


## 2024 年 3 月更新，单场比赛记分牌
- 如果你不需要一场锦标赛，只想记录此时此刻的单场比赛，现在可以创建单场赛事
- 与锦标赛相同的是，你需要从选手池里面挑选选手并为他报名。
- 为了比赛能在大部分设备上直观显示并易于操作，我们设计了一个简洁的记分牌视图，可以通过简单点击来快速记分。

## 2024 年 2 月更新，锦标赛和选手(Web Beta)

锦标赛：锦标赛支持单败、双败赛。分为三个阶段
- **准备阶段**，可以添加特定数量的选手参与比赛，比赛开始前可以随时添加或删除选手，比赛会自动根据选手数量、规则生成比赛对阵表。
- **对战阶段**，系统会提示当前正在进行的比赛组织者可标记选手得分，系统将自动根据规则晋级、淘汰选手。如果是双败赛还会自动将对应选手分配到败者组。
- **结束**，，会锁定所有记分操作，所有人可以回顾比赛。

其他功能：
- 添加选手。事先添加选手后就可以在比赛中选择他，可设置选手的头像、昵称、主题色。
- 参赛选手无需登录平台，选手的信息均由主办方负责维护。



## 2024 年 1 月更新， iOS 平台适配

- 为了确保更好的用户体验，开发者学习了 SwiftUI 开发相关技术，并决定同时开发 iOS 和 Web 版应用。
- 应用将会同时适配深色、浅色模式，同时支持 iPad、iPhone，且 Web 版会支持大部分桌面显示器和电视屏幕。
- 两个平台的功能都不会缺失，但是会根据平台特性进行调整。


`}

