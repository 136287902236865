import { post } from "@/utils/laf-db"

export class Player {
  constructor(json) {
    this.owner = json.owner
    this.id = json._id
    this.name = json.name

    // let defaultAvatar = 'https://www.streetfighter.com/6/assets/images/index/logo_mark.png'
    // let defaultAvatar = '/default-avatar2.svg'
    this.avatar = json.avatar
    this.color = json.color
    this.asAccount = json.asAccount || false
    // this.playerColor ? or default avatar
  }


  // 删除自身
  removeSelf = async () => {
    return await post('/players/delete', { playerId: this.id })
  }
}
