import { forwardRef, useImperativeHandle, useState } from 'react'
import { Dialog, DialogContent, DialogOverlay, DialogTrigger } from '@/shadcn/components/ui/dialog'
import { RefPackaging } from '@/shadcn/utils'

function ScreenModalRef(props, ref) {
  let { triggerButton, } = props
  // let { title, description } = props
  // let { triggerClass, dialogClass } = props
  let [open, setOpen] = useState(false)

  useImperativeHandle(ref, () => {
    return { setOpen }
  }, [])

  return (
    <Dialog open={open} onOpenChange={setOpen} >
      <DialogTrigger asChild>
        <RefPackaging  >
          <>
            {triggerButton}
            {props.renderTrigger?.({
              open: () => setOpen(true),
            })}
          </>
        </RefPackaging>
      </DialogTrigger>
      <DialogContent style={{ minHeight: "100dvh", minWidth: "100dvw" }}>
        <DialogOverlay className="overflow-auto" >
          {props.children}
          {props.render?.({
            close: () => setOpen(false),
          })}
        </DialogOverlay>
      </DialogContent>
    </Dialog >
  )

}

export const ScreenModal = forwardRef(ScreenModalRef)


