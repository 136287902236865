import { useAccountAsPlayer } from '@/hooks/use-players'
import { Button } from '@/shadcn/components/ui/button'
import { Link, useSearchParams } from "react-router-dom";
import { useGameEventFullData } from '@/hooks/use-events';
import { PlayerSmallCard } from '@/components/player-card';
import { EventCell } from '../events/event-page-list';
import { ArrowUpIcon, CheckCircle, HandHelpingIcon, LogInIcon, LogOutIcon } from 'lucide-react';
import { ContentUnavailable } from '@/shadcn/components/_content-unavailable';
import { Modal } from '@/shadcn/components/_modal';
import { Login } from '../account/login';
import { post } from '@/utils/laf-db';


export function RegistrationPage() {
  // 获取路由 query
  let [query] = useSearchParams()
  let eventId = query.get("eid")
  let { data: event, mutate: mutateEvent } = useGameEventFullData(eventId)
  const { data: player, mutate: mutateAccountAsPlayer } = useAccountAsPlayer()

  const confirmRegister = async () => {
    // console.log({ eventId: event.id, playerId: player.id })
    await post(`/events/player-register`, { eventId: event.id, playerId: player.id })
    await mutateEvent()
    await mutateAccountAsPlayer()
  }

  if (!event) return (<></>)

  // id 不正确或不属于公开比赛
  if (!event.id) return <ContentUnavailable
    title="无法找到比赛"
    description="可能您的 URL 有误，或比赛已被删除、隐藏。"
    action={(
      <Link to="/events">
        <Button className="mt-auto">查看所有比赛</Button>
      </Link>
    )}
  />
  if (event.registrationPage !== true) return <ContentUnavailable
    title="比赛不支持自主报名"
    icon={<HandHelpingIcon className="w-12 h-12 opacity-60" />}
    description="如需报名，请联系主办方。"
    action={(
      <Link to={`/events/${event.id}`}>
        <Button className="mt-auto">返回赛事主页</Button>
      </Link>
    )}
  />

  // 未登录
  if (!player) {
    return (
      <div className='flex flex-col gap-4'>
        <EventCell event={event} className="bg-secondary rounded-xl transition-all w-full" />
        <ContentUnavailable
          className={"flex-grow"}
          title="报名需登录"
          icon={<LogOutIcon className="w-12 h-12 text-muted-foreground" />}
          description="主办方允许赛事自主报名，但您需要先登录以供辨识。"
          action={(
            <Modal
              triggerButton={(
                <Button>
                  <LogInIcon className="h-4 w-4 mr-2" />
                  <span>登录</span>
                </Button>
              )}
              tooltipContent={"Login"}
              tooltipSide={"right"}
              render={({ close }) => {
                return (<Login
                  callback={() => {
                    mutateEvent()
                    mutateAccountAsPlayer()
                  }}
                  close={() => {
                    close()
                  }} />)
              }}
            />
          )}
        />
      </div>
    )
  }

  // 已经报过名了
  if (event.attendeeIds.includes(player.id)) {
    return (
      <ContentUnavailable
        title="您已报名"
        icon={<CheckCircle className="w-12 h-12 text-green-400" />}
        description="可前往赛事主页查看更多信息"
        action={(
          <Link to={`/events/${event.id}`}>
            <Button className="mt-auto">赛事主页</Button>
          </Link>
        )}
      />
    )
  }

  // 提交报名
  return (
    <div className="flex flex-col bg-secondary rounded-xl p-4">
      <div className='flex flex-col items-center justify-center gap-8 '>
        <EventCell event={event} className="bg-background rounded-xl transition-all w-full" />
        <ArrowUpIcon className='w-12 h-12 opacity-50 animate-in ease-out fade-in-25 slide-in-from-bottom-8 duration-500' />
        <PlayerSmallCard player={player} className="bg-background p-4" />
      </div>

      {event.stage === "1-REGISTRATION" ? (
        <Button className="mt-auto" onClick={confirmRegister}>确认报名</Button>
      ) : (
        <Button className="mt-auto" disabled={true}>无法报名，比赛不处于报名阶段</Button>
      )}
    </div>
  )
}


