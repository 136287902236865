import React, { useEffect, useState } from 'react'
import { cn } from '../utils'

let fallbackImage = "/images/image-fallback.svg"

export const ImageWithFallback = ({ src, fallback, className, ...props }) => {
  const [state, setState] = useState('loading')
  useEffect(() => {
    const img = new Image()
    img.onload = () => setState('success')
    img.onerror = () => setState('error')
    if (!src) {
      setState('error')
    }
    img.src = src
  }, [src])

  return (
    <>
      {state === 'loading' && <img src={fallbackImage} alt="loading" draggable={false} />}
      {state === 'error' && (
        <div className='flex-center w-full h-full'>
          <img src={fallbackImage} className={cn("w-16 h-16")} alt="error" draggable={false} />
        </div>
      )}
      {state === 'success' && (
        <img src={src} alt="success" draggable={false} className={cn("w-full h-full ", className)} {...props} />
      )}
    </>
  )
}
