import { forwardRef } from 'react'
import * as z from "zod"
import { cn } from "@/shadcn/utils"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/shadcn/components/ui/form'
import { Button } from '@/shadcn/components/ui/button'
import { useForm } from 'react-hook-form'
import { zodResolver } from "@hookform/resolvers/zod"
import { Input } from '@/shadcn/components/ui/input'
import { Popover, PopoverContent, PopoverTrigger } from '@/shadcn/components/ui/popover'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from '@/shadcn/components/ui/command'
import { Check, ChevronDown } from "lucide-react"
import { post } from '@/utils/laf-db'
import { useStaticData } from '@/utils/app-init'
import { Switch } from '@/shadcn/components/ui/switch'


const formSchema = z.object({
  eventName: z.string().min(2, {
    message: "Event Name must be at least 2 characters.",
  }),
  description: z.string(),
  gameKey: z.string({
    required_error: "Please select a game.",
  }),
  registrationPage: z.boolean(),
})


export const EventEditorRef = ({ event, scene, mutate, close }, ref) => {
  const { games } = useStaticData()
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      eventName: event?.name || "",
      description: event?.description || "",
      gameKey: event?.gameKey || "sf6",
      registrationPage: event?.registrationPage || false,
    },
  })

  const saveChange = async (call) => {
    let result = await form.trigger()
    console.log('result', result)
    if (!result) { return }
    let values = form.getValues()
    let body = {
      eventId: event.id,
      name: values.eventName,
      description: values.description,
      gameKey: values.gameKey,
      registrationPage: values.registrationPage,
    }
    await post('/events/set', body)
    call()
  }

  return (
    <Form {...form} >
      <form className="grid gap-4 grid-cols-4 border p-4 rounded-lg bg-secondary">
        {/* <div className='col-span-1'>
          <AspectRatio ratio={1} className="h-20">
            <ImageWithFallback
              src={event?.poster}
              sizes="256w"
              className="rounded-lg object-cover w-full h-full"
              alt="event poster"
            />
          </AspectRatio>
          <div className='text-secondary-foreground opacity-80 text-xs'>
            支持修改基本比赛信息，如需添加选手，请在比赛处于“报名阶段”时，点击“调整选手”按钮。
          </div>
        </div> */}
        <div className="space-y-4 col-span-4">
          <FormField
            control={form.control}
            name="eventName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>名称</FormLabel>
                <FormControl>
                  <Input placeholder="" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>描述</FormLabel>
                <FormControl>
                  <Input placeholder="" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="gameKey"
            render={({ field }) => {
              let game = games.find(i => i.key === field.value)
              return (
                <FormItem>
                  <FormLabel>游戏或运动</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild >
                      <FormControl>
                        <Button
                          variant="outline"
                          role="combobox"
                          className={cn(
                            "w-full justify-between",
                            { "text-muted-foreground": !field.value }
                          )}
                        >
                          {field.value ? game?.label : "Select game"}
                          <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="p-0 w-full" align="end">
                      <Command>
                        <CommandInput placeholder="Search game..." />
                        <CommandEmpty>No game found.</CommandEmpty>
                        <CommandGroup>
                          {games.map((i) => (
                            <CommandItem
                              key={i.key}
                              value={i.label}
                              onSelect={() => form.setValue("gameKey", i.key)}
                            >
                              <Check
                                className={cn("mr-2 h-4 w-4",
                                  { "opacity-100": i.key === field.value },
                                  { "opacity-0": i.key !== field.value },
                                )}
                              />
                              {i.label}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </Command>
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )
            }}
          />

          {event.visibility === "public" && (
            <FormField
              control={form.control}
              name="registrationPage"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>提供自主报名界面</FormLabel>
                  <FormControl>
                    <label className="flex items-center h-10">
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                      <span className="ml-2 text-xs text-secondary-foreground select-none">用户可通过访问网址自行注册报名</span>
                    </label>
                  </FormControl>
                </FormItem>
              )}
            />
          )}

          <Button className="w-full" size=""
            onClick={async (e) => {
              e.preventDefault()
              saveChange(async () => {
                await mutate()
                close()
              })
            }}>
            保存
          </Button>

        </div>
      </form>
    </Form>
  )
}


let EventEditor = forwardRef(EventEditorRef)
export { EventEditor }