import { forwardRef } from 'react'
import { useToggle } from 'react-use'
import { Input } from './ui/input'
import { Button } from './ui/button'
import { Eye, EyeOff } from 'lucide-react'


const PasswordInput = forwardRef(({ placeholder, ...field }, ref) => {
  const [showPassword, switchShowPassword] = useToggle(false)

  return (
    <div className='relative'>
      <Input
        placeholder={placeholder}
        {...field}
        type={showPassword ? "text" : "password"}
      />
      <div className='absolute right-0 top-0'>
        <Button variant="icon" className="" onClick={(e) => {
          e.preventDefault()
          switchShowPassword()
        }}>
          {showPassword ? <EyeOff className='w-4 h-4' /> : <Eye className='w-4 h-4' />}
        </Button>
      </div>
    </div>
  )
})

export { PasswordInput }
