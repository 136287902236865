import { cn } from "@/shadcn/utils"
import { PlayerAvatar } from "@/shadcn/components/_player-avatar"

export function PlayerSmallCard({ className, player, onClick, appendRender }) {

  return (
    <div
      // data-pid={player.id}
      className={cn("flex items-center p-2 rounded-md", className)}
      onClick={onClick}
    >
      <PlayerAvatar player={player} className='w-6 h-6' />
      <div className="ml-2 space-y-1 overflow-hidden">
        <p className="text-sm font-medium leading-none truncate">
          {player.name}
        </p>
      </div>
      {appendRender}
    </div>
  )
}
