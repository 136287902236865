import * as z from "zod"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/shadcn/components/ui/form'
import { Button } from '@/shadcn/components/ui/button'
import { useForm } from 'react-hook-form'
import { zodResolver } from "@hookform/resolvers/zod"
import { Input } from '@/shadcn/components/ui/input'
import { post } from '@/utils/laf-db'
import { HeaderText } from '@/components/header-text'
import { useNavigate } from "react-router-dom"


const formSchema = z.object({
  name: z.string().min(2, { message: "Name must be at least 2 characters." }),
})

export const CreateCommunity = ({ event, scene }) => {
  const navigate = useNavigate()

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: event?.name || "",
    },
  })

  const onCreate = async () => {
    let result = await form.trigger()
    if (!result) { return }
    let values = form.getValues()
    let body = {
      name: values.name,
      memberIds: [],
    }
    let { data } = await post("/community/add", body)
    if (data.insertedId) {
      navigate(`/communities/${data.insertedId}`, { replace: true })
    }
  }

  return (
    <div>
      <HeaderText>创建社区</HeaderText>
      <Form {...form}>
        <form className="" onSubmit={(e) => { e.preventDefault() }} >
          <div className='flex mt-8 phone:flex-col phone:p-2'>
            <div className="pad:w-36 w-48 text-xl self-start flex shrink-0">基本信息</div>

            <div className='flex-grow phone:mt-4 space-y-8'>
              {/* 社区名 */}
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem className="">
                    <FormLabel>名称</FormLabel>
                    <FormControl>
                      <Input {...field}
                        placeholder=""
                        onChange={e => {
                          field.onChange(e)
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </form>
      </Form>

      <div className='flex mt-16 phone:flex-col phone:p-2'>
        <Button className="w-full mt-4" onClick={onCreate}>
          确认创建社区
        </Button>
      </div>
    </div>
  )
}