
import { cn } from "@/shadcn/utils"
import { Button } from '@/shadcn/components/ui/button'
import { Plus, Minus, X, RotateCcw, Settings } from 'lucide-react'
import { usePublicPlayer } from '@/hooks/use-players'
import { PlayerAvatar } from '@/shadcn/components/_player-avatar'
import { playerThemeColors } from '../players/form-editor'
import { useKeyPressEvent, useMedia } from 'react-use'
import { useState } from "react"
import { Tooltip } from "@/shadcn/components/_tooltip"

export const FixedScoreView = ({ match, mutate, disabled, close, onOpenSetting }) => {
  const gridCols = match?.scoreboard?.length || 2
  const isPhone = useMedia('(max-width: 450px)')
  useKeyPressEvent('Escape', () => {
    close()
  })
  return (
    <div
      id="fixed-scoreboard"
      className={cn(
        "fixed top-0 left-0 z-[100] cursor-default",
        "w-dvw h-dvh backdrop-blur-sm",
      )}
      onClick={() => close()}
    >
      <div className={`absolute w-dvw be-center-vertically`}
        onClick={(e) => { e.stopPropagation() }}
      >

        <div className={`grid grid-cols-${gridCols} gap-4 px-4 h-80 phone:h-[40rem]`}>
          {match.scoreboard.map((score, index) => {
            // 垂直对称，只有手机场景适用
            let verticalSymmetry = isPhone && match.scoreboard.length === 2 && index === 1

            return <Score
              key={`score-live-full-${index}`}
              index={index}
              disabled={disabled}
              match={match}
              reload={mutate}
              playerId={score.playerId}
              score={score.value}
              className={"col-span-1 phone:col-span-2"}
              avatarClassName={cn({
                "bottom-4": verticalSymmetry,
                "top-4": !verticalSymmetry,
              })}
            />
          })}
        </div>

        <div className="px-4 mt-4">
          <div className="rounded-xl p-2 flex gap-4 m-auto line flex-center border bg-background border-foreground">
            <Tooltip label="关闭">
              <Button
                onClick={() => close()}
                size="icon" className="rounded-full size-8"
              >
                <X className="size-4" />
              </Button>
            </Tooltip>

            <Tooltip label="刷新">
              <Button
                onClick={() => mutate()}
                size="icon" className="rounded-full size-8"
              >
                <RotateCcw className="size-4" />
              </Button>
            </Tooltip>

            {onOpenSetting !== undefined && (
              <Tooltip label="设置">
                {/* <Link to={`./${match.id}`}> */}
                <Button
                  onClick={onOpenSetting}
                  size="icon" className="rounded-full size-8"
                >
                  <Settings className="size-4" />
                </Button>
                {/* </Link> */}
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const Score = ({ match, reload, playerId, score, className, avatarClassName, disabled, index }) => {
  let { data: player } = usePublicPlayer({ byId: playerId })
  let theme = playerThemeColors[player?.color ? player?.color : "Zinc"]
  let [loading, setLoading] = useState(false)
  return (
    <div className={cn("flex-center relative rounded-xl group/score",
      {
        "border-2 bg-background": !theme,

      },
      className)
    }
      style={{ background: `linear-gradient(to right bottom, ${theme?.light}, ${theme?.dark})` }}
    >
      <div className={cn("absolute flex items-center space-x-2", avatarClassName)}>
        <PlayerAvatar player={player} />
        <div className="text-lg">{player?.name}</div>
      </div>

      <div className={cn("text-9xl font-number",
      )}>
        {score}
      </div>

      {disabled !== true && (
        <>
          <Button
            onClick={async () => {
              setLoading(true)
              await match.moveScore({ value: -1, index })
              await reload()
              setLoading(false)
            }}
            size="icon"
            variant="ghost"
            disabled={loading}
            className={cn(
              "absolute top-1/2 -translate-y-16 w-1/3 h-32 left-4 rounded-xl",
              "opacity-0 group-hover/score:opacity-100",
              { "group-hover/score:opacity-30": !!theme }
            )}
          >
            <Minus />
          </Button>

          <Button
            onClick={async () => {
              setLoading(true)
              await match.moveScore({ value: 1, index })
              await reload()
              setLoading(false)
            }}
            size="icon"
            variant="ghost"
            disabled={loading}
            className={cn(
              "absolute top-1/2 -translate-y-16 w-1/3 h-32 right-4 rounded-xl",
              "opacity-0 group-hover/score:opacity-100",
              { "group-hover/score:opacity-30": !!theme }
            )}
          >
            <Plus className="w-12 h-12" />
          </Button>
        </>
      )}

    </div>
  )
}