import React, { useState } from 'react'
import { Button } from '@/shadcn/components/ui/button'
import { ArmchairIcon } from 'lucide-react'
import { PlayerSmallCard } from '@/components/player-card'
import { Modal } from '@/shadcn/components/_modal'
import { PlayersPicker } from '@/components/players-picker'
import { post } from '@/utils/laf-db'

export function CommunityMembers({ community }) {
  const [selectIds, setSelectIds] = useState(community.members.map(i => i.id) || [])

  return (
    <div>
      <div className='flex'>
        <div className='font-bold'>社区选手</div>
        <div className='ml-auto text-xs'>Members {community.members?.length} / 256</div>
      </div>
      <div className='flex items-center bg-zinc-200 p-4 rounded-lg'>
        <div className='flex gap-2'>
          {community.members?.map(player => {
            return <PlayerSmallCard key={player.id} player={player} className='bg-primary-foreground' />
          })}
          <Modal
            title="调整社区内选手"
            triggerButton={(
              <Button variant="outline">
                <ArmchairIcon className='w-4 h-4 mr-1' />
                <span>调整社区内选手</span>
              </Button>
            )}>

            <PlayersPicker value={selectIds} onChange={setSelectIds} />
            <div className='flex gap-2 justify-end'>
              <Button onClick={async () => {
                await post("/community/set", { id: community.id, memberIds: selectIds })
              }}>保存</Button>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  )
}
