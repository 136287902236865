import { Tooltip } from "@/shadcn/components/_tooltip"
import { ImageWithFallback } from "@/shadcn/components/_image-with-fallback"

export const PosterPicker = ({ field, posters }) => {
  return (
    <div className="h-36 bg-secondary rounded-xl p-2 flex">
      <div className="flex flex-col items-center mr-12 phone:mr-6">
        <Tooltip label="当前海报">
          <div className="h-32 w-32 rounded-xl bg-background">
            <ImageWithFallback
              src={field.value}
              className="rounded-lg object-cover"
              alt="event poster"
            />
          </div>
        </Tooltip>
      </div>

      <div className="w-0 h-32 flex flex-grow flex-nowrap gap-2 p-2 overflow-x-auto">
        {posters.map((i, index) => (
          <div key={`poster_${index}`}
            className="h-28 w-28 flex-shrink-0 cursor-pointer"
            onClick={() => {
              field.onChange(i)
            }}
          >
            <ImageWithFallback
              src={i}
              className="rounded-lg object-cover grayscale hover:grayscale-0 transition-all"
              alt="event poster"
            />
          </div>
        ))}
      </div>
    </div>
  )
}