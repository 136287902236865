import { Link } from "react-router-dom"
import { docs } from "."

export const PostListPage = () => {
  return (
    <div className="divide-y">
      {docs.map(i => {
        return (
          <div key={i.title} className="py-4">
            <Link className="font-semibold text-xl line-clamp-1 not-italic hover:underline" to={i.to}>
              {i.title}
            </Link>
            <p className="text-sm text-gray-500 dark:text-gray-400">更新于：{i.date}</p>
          </div>
        )
      })}
    </div>
  )
}
