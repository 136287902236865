const groupByRound = (losers) => {
  // 按 round 分组
  const groupedByRound = losers.reduce((acc, current) => {
    const round = current.round;
    if (!acc[round]) {
      acc[round] = [];
    }
    acc[round].push(current);
    return acc;
  }, {});

  // 转换成二维数组并按 round 排序
  return Object.keys(groupedByRound)
    .sort((a, b) => b - a)
    .map(round => groupedByRound[round]);
}

const getWinnerAndLoser = (scoreboard, group = "") => {
  // 这个函数只能处理 a,b 数字比大小的场景
  let a = scoreboard?.[0]
  let b = scoreboard?.[1]
  let playerIds = scoreboard.map(i => i.playerId)
  let winner = ""
  let loser = ""
  let draw = false
  let byes = false

  if (scoreboard.length === 0) {
    // 空比赛，没有任何参赛选手
  } else if (scoreboard.length === 1) {
    // 表示这里出现了轮空
    byes = true
  } else if (Number(a.value) > Number(b.value)) {
    winner = a.playerId
    loser = b.playerId
  } else if (Number(a.value) < Number(b.value)) {
    winner = b.playerId
    loser = a.playerId
  } else {
    // 平局
    draw = true
  }
  return {
    playerIds,
    winner,
    loser,
    draw,
    byes,
    group,
  }
}

export const doubleRanking = ({ matches, attendees }) => {
  let orderMatches = [...matches]
  orderMatches.sort((a, b) => {
    if (a.tournamentProperties.group === "win") return -1
    return b.tournamentProperties.round - a.tournamentProperties.round
  })

  let cache = []
  let rankNumber = 1
  const rankOne = (playerId) => {
    // 检查 cache 是否已经存在
    let player = cache.find(i => i.playerId === playerId)
    if (!player) {
      cache.push({ playerId, rank: rankNumber })
    }
  }
  // console.log(orderMatches.map(i => i.tournamentProperties.number))
  let finalMatch = orderMatches[0]
  if (finalMatch?.scoreboard?.length === 0) {
    // 忽略 reset，取胜者组冠军
    let winnerGroup = orderMatches.filter(i => i.tournamentProperties.group === "win" && i.scoreboard.length > 0)
    finalMatch = winnerGroup[0]
    // console.log("胜者组冠军 finalMatch", winnerGroup)
    // finalMatch = winnerGroup.reduce((a, b) => {
    //   if (a === undefined) return b
    //   if (b.tournamentProperties.number > a.tournamentProperties.number) return b
    // })
    // console.log("胜者组冠军 finalMatch", finalMatch)
  }
  if (finalMatch?.scoreboard) {
    let { winner, loser } = getWinnerAndLoser(finalMatch.scoreboard)
    rankOne(winner)
    rankNumber += 1

    rankOne(loser)
    rankNumber += 1
  }
  let loserPipe1 = orderMatches.filter(i => i.tournamentProperties.group === "lose")
  // console.log("败者组逐个查看选手", loserGroup)
  // let currentRound = Math.max(...loserGroup.map(i => i.tournamentProperties.round))

  let loserPipe2 = loserPipe1.map(i => {
    return {
      round: i.tournamentProperties.round,
      loser: getWinnerAndLoser(i.scoreboard).loser
    }
  }).filter(i => i.loser)

  let loserPipe3 = groupByRound(loserPipe2)

  loserPipe3.forEach(roundArray => {
    let count = roundArray.length
    roundArray.forEach(i => {
      rankOne(i.loser)
    })
    rankNumber += count
  })

  let rankingResult = cache.map(i => {
    let player = attendees.find(player => player._id === i.playerId)
    return { ...player, ...i }
  })

  return rankingResult
}


export const singleRanking = ({ matches, attendees }) => {
  let orderMatches = [...matches]
  orderMatches.sort((a, b) => {
    return b.tournamentProperties.round - a.tournamentProperties.round
  })

  let cache = []
  let rankNumber = 1
  const rankOne = (playerId) => {

    cache.push({ playerId, rank: rankNumber })

  }

  let finalMatch = orderMatches[0]
  if (finalMatch?.scoreboard) {
    // 第一个记最终场冠军，其他所有人按轮次排位即可
    let { winner } = getWinnerAndLoser(finalMatch.scoreboard)
    rankOne(winner)
    rankNumber += 1
  }

  let loserPipe2 = orderMatches.map(i => {
    return {
      round: i.tournamentProperties.round,
      loser: getWinnerAndLoser(i.scoreboard).loser
    }
  }).filter(i => i.loser)

  let loserPipe3 = groupByRound(loserPipe2)

  loserPipe3.forEach(roundArray => {
    let count = roundArray.length
    roundArray.forEach(i => {
      rankOne(i.loser)
    })
    rankNumber += count
  })

  let rankingResult = cache.map(i => {
    let player = attendees.find(player => player._id === i.playerId)
    return { ...player, ...i }
  })
  return rankingResult
}



// 先从前端生成 ranking 因为算法尚不稳定和完善，还未通过完全的测试用例
export const useFrontendRanking = (event) => {
  let matches = event.matchController.matches
  
  let ranking = []
  if (event.ruleTemplateKey === "tournament-1") {
    ranking = singleRanking({ matches, attendees: event.attendees })
  } else if (event.ruleTemplateKey === "tournament-2") {
    ranking = doubleRanking({ matches, attendees: event.attendees })
  }
  
  ranking.map(i => {
    i.player = event.attendees.find(a => a.id === i.playerId)
    return i
  })
  return { ranking }
}