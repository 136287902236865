import useSWR from 'swr'
import { post } from '@/utils/laf-db'
import { Player } from '@/models/player'

export const usePlayers = (props = {}) => {
  let { limit, search, swrKey, stopRequest } = props
  const fetcher = async () => {
    if (stopRequest === true) {
      return []
    }
    let { data } = await post('/players/get', {
      limit: limit || 500,
      search: search || "",
    })
    const pls = data.map((i) => new Player(i))
    return pls
  }
  const swrResult = useSWR(`laf-sdk:get-players:${swrKey}`, fetcher, {
    // revalidateIfStale: false,
    // revalidateOnFocus: false,
    // revalidateOnReconnect: false,
  })
  return swrResult
}

export const usePublicPlayer = ({ byId }) => {
  const fetcher = async () => {
    if (!byId) { return }
    let { data } = await post('/public-read/players', {
      limit: 1,
      filterIds: [byId],
    })
    const pls = data.map((i) => new Player(i))
    return pls[0]
  }
  const swrResult = useSWR(`laf-sdk:get-single-player:${byId}`, fetcher, {
    // 禁用 3 个自动重刷策略，在开发阶段为了可控和后续省流的目的
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })
  return swrResult
}

// 请求自己账户名下的 Players 信息
export const useAccountAsPlayer = () => {
  const fetcher = async () => {
    let { data } = await post('/players/get', {
      limit: 1,
      asAccount: true,
    })
    if (data) {
      const pls = data.map((i) => new Player(i))
      return pls[0]
    }
  }
  const swrResult = useSWR(`laf-sdk:get-players-as-account`, fetcher, {
    // 禁用 3 个自动重刷策略，在开发阶段为了可控和后续省流的目的
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })
  return swrResult
}


// 仅供选择器使用，接口也是要鉴权的
export const usePlayersPickerSource = (props) => {
  const fetcher = async () => {
    let { data } = await post('/players/get', {
      filterIds: props.filterIds,
      limit: props.limit || 500,
      search: props.search || "",
    })
    const pls = data.map((i) => new Player(i))
    return pls
  }

  // const swrResult = useSWR(`laf-sdk:get-players-picker-source:${swrKey}`, fetcher)
  const swrResult = useSWR(props, fetcher, {
    keepPreviousData: true,
  })
  return swrResult
}
