import { forwardRef, useRef, useState } from "react"
import { HeaderText } from "@/components/header-text"
import { useMatches } from "@/hooks/use-matches"
import { Modal } from "@/shadcn/components/_modal"
import { Button } from "@/shadcn/components/ui/button"
import { cn } from "@/shadcn/utils"
import { Plus, RotateCcw, Swords } from "lucide-react"
import { Separator } from "@/shadcn/components/ui/separator"
import { MatchEditor } from "./form-editor"
import { Tooltip } from '@/shadcn/components/_tooltip'
import { PlayerAvatar } from "@/shadcn/components/_player-avatar"
import { FixedScoreView } from "./fixed-score-view"
import { ContentUnavailable } from "@/shadcn/components/_content-unavailable"


const MatchCard = forwardRef(({ match, onClick }, ref) => {
  let { scoreboard, name, stage } = match
  let p1 = scoreboard?.[0].player
  let p2 = scoreboard?.[1].player
  let p1Win = Number(scoreboard?.[0]?.value) > Number(scoreboard?.[1]?.value)
  let p2Win = Number(scoreboard?.[1]?.value) > Number(scoreboard?.[0]?.value)
  let same = Number(scoreboard?.[1]?.value) === Number(scoreboard?.[0]?.value)

  return (
    <div
      onClick={onClick}
      className={cn(
        "h-24 w-full px-6",
        "rounded-xl flex relative items-center bg-secondary",
        "border-2 border-secondary hover:border-foreground cursor-pointer transition-all",
      )}
    >
      <div className="flex flex-col items-center relative">
        <PlayerAvatar player={p1} className="w-10 h-10" />
        <div className="text-foreground opacity-60 text-xs absolute -bottom-4 truncate w-[4.5rem] text-center">
          {p1?.name}
        </div>
      </div>

      <div className="grid gap-0 grid-cols-3 w-full">
        <div className={cn("col-span-1 text-4xl text-center font-number", {
          "opacity-20": p2Win && !same
        })}>
          {scoreboard?.[0]?.value}
        </div>

        <div className="col-span-1 flex-center">
          <div className="truncate opacity-50 text-sm">{name}</div>

          <Tooltip label={stage || "in progress"}>
            <div className="absolute bottom-2 flex space-x-1">
              <div className="h-2 w-5 rounded-full bg-foreground opacity-80" />
              <div className={cn("h-2 w-5 rounded-full bg-foreground opacity-10", {
                "opacity-80": stage === "finished"
              })} />
            </div>
          </Tooltip>
        </div>

        <div className={cn("col-span-1 text-4xl text-center font-number", {
          "opacity-20": p1Win && !same
        })}>{scoreboard?.[1]?.value}</div>

      </div>
      <div className="flex flex-col items-center relative">
        <PlayerAvatar player={p2} className="w-10 h-10" />
        <div className="text-foreground opacity-60 text-xs absolute -bottom-4 truncate w-[4.5rem] text-center">
          {p2?.name}
        </div>
      </div>
    </div>
  )
})

export function MatchesPageList() {
  let { data, mutate } = useMatches()
  let matches = data?.matches || null
  let paging = data?.paging || null

  const matchCreatorRef = useRef()
  const matchEditorRef = useRef()
  const settingsRef = useRef()
  const matchItem = useRef()
  const [openMatch, setOpenMatch] = useState(null)

  return (
    <div>
      <div className="flex items-center mb-8">
        <HeaderText>记分板</HeaderText>

        <div className="ml-auto flex space-x-2">
          <Button variant="outline" size="icon" onClick={mutate} >
            <RotateCcw className='w-4 h-4' />
          </Button>
          <Modal
            title="添加记分"
            triggerButton={(
              <Button variant="outline" size="icon"  >
                <Plus className='w-4 h-4' />
              </Button>
            )}
            render={({ close }) => <Button className="w-full"
              onClick={async () => {
                let { create } = matchCreatorRef.current
                create(async () => {
                  await mutate()
                  close()
                })
              }}>
              Save
            </Button>}
          >
            <MatchEditor scene="create" ref={matchCreatorRef} />
            <Separator className="my-6" />
          </Modal>
        </div>
      </div>

      {paging?.total > 0 && (
        <div className="text-muted-foreground flex my-4" >
          <div className="ml-auto">
            <span>
              共 {paging?.total} 场比赛，已显示 {matches?.length || 0} 场
            </span>
          </div>
        </div>
      )}

      <div className="flex flex-col space-y-1">
        {Array.isArray(matches) && matches?.map(i => {
          return (
            <div className="flex items-center" key={i.id}>

              <MatchCard match={i} onClick={() => setOpenMatch(i)} />

              {openMatch && openMatch.id === i.id && (
                <FixedScoreView
                  match={i} // 必须要引用 i, mutate 会刷新 i
                  mutate={mutate}
                  close={() => setOpenMatch(null)}
                  // showSetting={true}
                  onOpenSetting={() => {
                    setOpenMatch(null)
                    matchItem.current = i
                    settingsRef.current.setOpen(true)
                  }}
                />
              )}
            </div>
          )
        })}

        {(!matches || matches.length === 0) && (
          <ContentUnavailable
            title="暂无记分"
            icon={<Swords className="w-12 h-12 text-muted-foreground" />}
            description="请新建一个记分，为选手记录分数"
            className="min-h-[75dvh]"
            action={(
              <Modal
                title="添加记分"
                triggerButton={<Button>新建记分</Button>}
                render={({ close }) => <Button className="w-full"
                  onClick={async () => {
                    let { create } = matchCreatorRef.current
                    create(async () => {
                      await mutate()
                      close()
                    })
                  }}>
                  Save
                </Button>}
              >
                <MatchEditor scene="create" ref={matchCreatorRef} />
                <Separator className="my-6" />
              </Modal>
            )}
          />
        )}
      </div>


      <Modal
        ref={settingsRef}
        title="Match Setting"
        render={({ close }) => (
          <div className="flex gap-4">
            <Button className="w-full" variant="secondaryDestructive" onClick={async () => {
              await matchItem.current.removeSelf()
              await mutate()
              close()
            }}>
              Delete
            </Button>

            <Button className="w-full"
              onClick={async () => {
                let { saveChange } = matchEditorRef.current
                saveChange(matchItem.current.id, async () => {
                  await mutate()
                  close()
                })
              }}>
              Save
            </Button>
          </div>
        )}
      >
        <MatchEditor match={matchItem.current} scene="edit" ref={matchEditorRef} />
        <Separator className="my-6" />
      </Modal>
    </div>
  )
}

