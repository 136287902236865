
import React from 'react'

import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar'
import { cn } from '../utils'
import { playerThemeColors } from '@/pages/players/form-editor'
import { User } from 'lucide-react'


export function PlayerAvatar({ player, className, fallbackClassName }) {
  let fb = fallbackClassName ? fallbackClassName : "text-sm"
  let color = playerThemeColors[player?.color ? player?.color : "Zinc"]
  let fallback = player?.name ? player?.name.slice(0, 1) : <User className='opacity-75' />
  return (
    <Avatar
      className={cn(`p-0.5`, className)}
      style={{ background: `linear-gradient(to bottom, ${color?.light}, ${color?.dark})` }}
    >
      <AvatarImage src={player?.avatar} alt="Avatar" className="rounded-full object-cover" />
      <AvatarFallback className={fb}>{fallback}</AvatarFallback>
    </Avatar>
  )
}


