import useSWR from 'swr'
import { post } from '@/utils/laf-db'
import { Community } from '@/models/community'


export const useCommunities = () => {
  const fetcher = async () => {
    let { data } = await post('/public-read/communities')
    const communities = data.map((i) => new Community(i))
    return communities
  }

  let config = {
    // revalidateIfStale: false,
    // revalidateOnFocus: false,
    // revalidateOnReconnect: false,
  }

  const swrResult = useSWR('laf-sdk:get-communities', fetcher, config)
  // swrResult.data maybe is undefined
  return swrResult
}

export const useCommunityFullData = (communityId) => {
  const fetcher = async () => {
    let { data } = await post('/public-read/community-detail', { communityId })
    const cmt = new Community(data)
    return cmt
  }

  let config = {
    // revalidateIfStale: false,
    // revalidateOnFocus: false,
    // revalidateOnReconnect: false,
    fallbackData: {},
  }

  const swrResult = useSWR(`laf-sdk:get-communities-full-data:${communityId}`, fetcher, config)
  // swrResult.data maybe is undefined
  return swrResult
}
