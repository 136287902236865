import React from 'react'
import { useGameEvents } from '@/hooks/use-events'
import { Button } from '@/shadcn/components/ui/button'
import { HeaderText } from '@/components/header-text'
import { Network, Plus, RotateCcw } from 'lucide-react'
import { ImageWithFallback } from '@/shadcn/components/_image-with-fallback'
import { AspectRatio } from '@radix-ui/react-aspect-ratio'
import { Link } from 'react-router-dom'
import { useCurrentUser } from '@/hooks/use-session'
import { cn } from '@/shadcn/utils'
import { ContentUnavailable } from '@/shadcn/components/_content-unavailable'
import { useToggle } from 'react-use'

export function PublicEventCell({ event }) {
  let i = event
  return (
    <div className='p-2'>
      <p className="truncate font-medium">{i.name}</p>

      <div className='flex overflow-clip h-14 items-center mt-1'>
        <div className='w-14 h-14 shrink-0 bg-background border-primary rounded-xl flex-center relative'>
          <AspectRatio ratio={1} className="">
            <div className="relative w-full h-full">
              <ImageWithFallback
                src={i?.poster}
                sizes="256w"
                className="rounded-lg object-cover"
                alt="event poster"
              />
            </div>
          </AspectRatio>
        </div>

        <div className="flex flex-col flex-grow ml-2">
          <p className="text-sm text-muted-foreground">{i.gameName}</p>
          <p className='text-sm text-nowrap'>由 {i.organizer?.displayName} 组织</p>
        </div>
      </div>
    </div >
  )
}

export function EventCell({ event, className }) {
  let i = event
  let visibilityText = i.visibility === "public" ? "公开可见" : "仅管理员可见"
  return (
    <div className={cn(className, "p-4")}>
      <p className="text-2xl font-medium leading-none mb-2">{i.name} </p>
      <div className={cn("flex items-center")} >
        {/* poster */}
        <div className='w-32 h-32 md:w-48 md:h-48 shrink-0 bg-background border-primary rounded-xl flex-center relative'>
          <AspectRatio ratio={1} className="p-1">
            <div className="relative w-full h-full">
              <ImageWithFallback
                src={i?.poster}
                sizes="256w"
                className="rounded-lg object-cover"
                alt="event poster"
              />
            </div>
          </AspectRatio>
        </div>

        <div className="ml-4 flex flex-col gap-1">
          <p className="text-muted-foreground">{i.gameName}</p>
          <p className="text-sm text-muted-foreground">{i.description}</p>
          <p className="text-sm font-medium leading-none mt-auto">{i.stageLabel}，{i.registrationText}</p>
          <p className={cn("text-sm text-muted-foreground phone:hidden")}>
            <span>{i.rulesText}，</span>
            <span>由 {i.organizer?.displayName} 组织，{visibilityText}</span>
          </p>
        </div>
      </div >
    </div>
  )
}

export function EventListPage() {
  const { data: account } = useCurrentUser()
  const { data: es, mutate } = useGameEvents()
  const [showAllPublicEvents, toggleAllPublicEvents] = useToggle(false)
  const publicEvents = (showAllPublicEvents ? es?.publicEvents : es?.publicEvents?.slice(0, 8)) || []
  const privateEvents = es?.privateEvents || []
  return (
    <div>
      <div className="flex items-center mb-8">
        <HeaderText>活动与赛事</HeaderText>
        <div className="ml-auto flex space-x-2">
          <Button variant="outline" size="icon" onClick={mutate} >
            <RotateCcw className='w-4 h-4' />
          </Button>
          {account && (
            <Link to="/events-create">
              <Button variant="outline" size="icon" >
                <Plus className='w-4 h-4' />
              </Button>
            </Link>
          )}
        </div>
      </div>

      <div className={cn({ hidden: publicEvents.length === 0 })}>
        <div className='mb-2 flex justify-between'>
          <div className='font-medium text-lg'>公开赛事</div>
          <Button variant="ghost" onClick={toggleAllPublicEvents}>
            {showAllPublicEvents ? "收起" : "查看全部公开赛事"}
          </Button>
        </div>
        <div className='grid grid-cols-3 phone:grid-cols-1 p-4 gap-2 max-h-72 bg-secondary rounded-lg overflow-scroll'>
          {publicEvents.map((i, index) => (
            <div key={`events_${i.id}`} className='bg-background border-2 border-secondary hover:border-foreground rounded-md cursor-pointer transition-all'>
              <Link to={`./${i.id}`}>
                <PublicEventCell event={i} />
              </Link>
            </div>
          ))}
        </div>
      </div>

      <div className={cn("mt-4")}>
        <div className='font-medium text-lg mb-2'>私有赛事</div>
        {privateEvents.map((i, index) => (
          <div key={`events_${i.id}`} className='mb-2 bg-secondary border-2 border-secondary hover:border-foreground rounded-xl cursor-pointer transition-all'>
            <Link to={`./${i.id}`}>
              <EventCell event={i} />
            </Link>
          </div>
        ))}
        {(privateEvents.length === 0) && (
          <ContentUnavailable
            title="暂无比赛"
            icon={<Network className="w-12 h-12 text-muted-foreground rotate-90" />}
            description="为选手报名，为比赛记分，点击按钮开始"
            className="min-h-[50dvh]"
            action={(
              <Link to="/events-create">
                <Button>新建比赛</Button>
              </Link>
            )}
          />
        )}
      </div>
    </div>
  )
}
