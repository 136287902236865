import { Button } from '@/shadcn/components/ui/button'
import { resetPasswordByEmail, sendEmailVerifyCode } from '@/components/laf-auth/account'
import { useNavigate } from 'react-router-dom'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/shadcn/components/ui/form'
import * as z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from 'react-hook-form'
import { InputOTP, InputOTPGroup, InputOTPSlot } from '@/shadcn/components/ui/input-otp'
import { useToggle } from 'react-use'
import { Input } from '@/shadcn/components/ui/input'
import { PasswordInput } from '@/shadcn/components/_password-input'
import { toast } from 'sonner'

const formSchema = z.object({
  code: z.string().length(6, "请输入完整的 6 位验证码"),
  email: z.string().email("请输入正确的邮箱地址"),
  password: z.string().min(3, { message: "Password must be at least 2 characters." }),
  confirmPassword: z.string().min(3, { message: "Password must be at least 2 characters." }),
}).refine((data) => data.password === data.confirmPassword, {
  message: "Passwords don't match",
  path: ["confirmPassword"], // path of error
})


export const ForgetPasswordPage = () => {
  const navigate = useNavigate()

  let [isSending, setIsSending] = useToggle(false)

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      code: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
  })


  const onSendCode = async () => {
    let result = await form.trigger("email")
    if (!result) { return }
    let { email } = form.getValues()

    let { code, message } = await sendEmailVerifyCode(email)
    if (code === 1) {
      toast("验证码已发送，请查收邮件。", { closeButton: true })
      setIsSending()
    } else {
      toast(message || "发送失败，请确认邮箱地址是否正确。", { closeButton: true })
    }
  }

  const onSubmit = async (values) => {
    let { email, code, password } = values
    console.log(email, code, password)

    // 根据返回值弹出是否核验成功，如果成功的话，关闭弹窗，刷新数据
    let result = await resetPasswordByEmail({
      email,
      code,
      newPassword: password,
    })
    if (result.code === 1) {
      toast('密码重置完毕，请使用新密码登录。', {
        action: {
          label: '回到主页',
          onClick: () => navigate("/")
        },
      })
      form.reset()
    } else {
      toast(result.message, { closeButton: true })
    }
  }


  return (
    <div className='flex flex-col'>
      <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">
        重置密码
      </h1>
      <div className='mt-4 text-muted-foreground'>在忘记密码的情况下，通过其他手段重置密码。</div>


      <Form {...form} >
        <form className="space-y-4 mt-12 px-4 lg:px-12" onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="flex flex-col lg:flex-row items-center rounded-lg bg-muted p-4 lg:space-y-0">
                <div className="space-y-0.5 w-full lg:w-80">
                  <FormLabel>Step 1</FormLabel>
                  <FormDescription>
                    填写已经认证的账户邮箱地址，并发送验证码
                  </FormDescription>
                </div>
                <FormControl>
                  <div className='flex flex-col lg:flex-row w-full lg:w-auto'>
                    <div>
                      <Input placeholder="邮箱地址" {...field} className="lg:w-[240px]" autoComplete="email" />
                      <FormMessage />
                    </div>
                    <Button
                      disabled={isSending}
                      className="mt-4 lg:ml-4 lg:mt-0"
                      onClick={(e) => {
                        e.preventDefault()
                        onSendCode()
                      }}
                    >
                      {isSending ? "已发送验证码" : "发送验证码"}
                    </Button>
                  </div>
                </FormControl>
              </FormItem>
            )}
          />


          <div className='flex flex-col lg:flex-row rounded-lg bg-muted p-4 lg:space-y-0'>
            <div className="space-y-0.5 flex flex-col mb-2 lg:w-80">
              <FormLabel>Step 2</FormLabel>
              <FormDescription>
                填写想要设置的新密码
              </FormDescription>
            </div>
            <div className='flex flex-col gap-2 lg:w-[240px]'>
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <>
                        <PasswordInput placeholder="新密码" {...field} autoComplete="new-password" />
                        <FormMessage />
                      </>
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="confirmPassword"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <>
                        <PasswordInput placeholder="确认新密码" {...field} autoComplete="new-password" />
                        <FormMessage />
                      </>
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </div>


          <FormField
            control={form.control}
            name="code"
            render={({ field }) => (
              <FormItem className="flex flex-col lg:flex-row items-center rounded-lg bg-muted p-4 lg:space-y-0">
                <div className='space-y-0.5 flex flex-col lg:w-80 self-start'>
                  <FormLabel>Step 3</FormLabel>
                  <FormDescription className="pb-2">
                    输入邮箱收到的 6 位验证码
                  </FormDescription>
                </div>

                <div className="">
                  <FormControl >
                    <InputOTP
                      maxLength={6}
                      value={field.value}
                      onChange={(value) => {
                        field.onChange(value)
                        if (value.length === 6) {
                          console.log("?")
                          form.handleSubmit(onSubmit)()
                        }
                      }}
                    >
                      <InputOTPGroup >
                        <InputOTPSlot index={0} className="bg-background" />
                        <InputOTPSlot index={1} className="bg-background" />
                        <InputOTPSlot index={2} className="bg-background" />
                        <InputOTPSlot index={3} className="bg-background" />
                        <InputOTPSlot index={4} className="bg-background" />
                        <InputOTPSlot index={5} className="bg-background" />
                      </InputOTPGroup>
                    </InputOTP>
                  </FormControl>
                </div>
              </FormItem>
            )}
          />
        </form>
      </Form >
    </div>
  )
}
