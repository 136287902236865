export const privacyPolicy = {
  title: "隐私策略",
  key: "privacy-policy",
  to: "/docs/privacy-policy",
  date: "2024-04-22",
  body: `
# 隐私策略

隐私策略阐释了正赛如何收集您的个人信息和数据，以及我们如何使用、分享和保护这些信息。

我们获取的关于您的任何信息和资料，都建立在您同意的基础上，如果缺失这些信息，我们会尽量确保只影响局部功能，不影响整个应用程序的正常使用。
如果缺失的信息会影响整个应用程序的正常使用，我们会在应用程序中明确告知您。

除法律法规、法律程序、公诉案件或主管部门强制性要求外，正赛不会主动公开披露用户的个人信息，如果存在其他需要公开披露个人信息的情形，我们会征得用户的明示同意。同时，我们保证披露采取符合法律和业界标准的安全防护措施。

正赛不会主动共享或转让你的个人信息至正赛外的第三方。

---
## 被收集的信息

正赛内部使用、无法被外部查看的信息：
- 设备和操作系统信息，为正常运行软件提供支持，确保软件适配不同的设备和操作系统。
- 账户标识 Account ID，您主动设置的非加密文本信息，用于登录和识别您的身份。如未设置，将无法通过 Account ID 登录，但可以通过其他方式登录。
- 密码，您主动设置的加密文本信息，用于登录和识别您的身份。如未设置，将无法通过密码登录，但可以通过其他方式登录。
- 邮箱地址，您主动设置的非加密文本信息，用于登录和找回密码。如未设置，将无法通过邮箱登录、找回密码，但可以通过其他方式登录。
- 手机号码，您主动设置的非加密文本信息，用于登录和找回密码。如未设置，将无法通过手机号码登录、找回密码，但可以通过其他方式登录。
- Apple ID，当您使用“通过 Apple 登录”功能时，第三方可能会将相关信息共享给正赛，以便正赛识别您的身份。

可能会被其他用户看见公开信息：
- 头像，您主动设置的用于辨识的图片，如不设置此信息正赛可能会为您显示一个默认图像，无论是否设置，均不影响您使用完整的程序功能。
- 显示名称，您主动设置的用于辨识的文本，如不设置此信息正赛可能会为您随机生成一个显示名称，无论是否设置，均不影响您使用完整的程序功能。


被共享给第三方的信息：
- 无

## 正赛如何使用信息
收集个人信息目的在于向你提供产品或服务，确保我们能遵守适用的相关法律法规。
- 向你提供、处理、维护、改善、开发我们的产品或提供给你的服务， 例如交付、验证、售后、客户支持和广告宣传。
- 在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向你提供的产品和服务的安全性。
- 内部目的，如数据分析、研究和开发与我们产品或服务的使用相关的统计信息，以更好地改进我们的产品或服务。
- 帮助我们设计新服务，改善我们现有服务。
- 执行软件验证、升级服务。
- 储存并维护与你相关的信息，用于我们的业务运营（例如数据同步）或履行法律义务。
- 其他征得你同意的目的。

## 删除账户
我们会以加密的方式存储您的信息，除了能确保您的数据安全以外，这也意味着即使是官方技术人员也无法在您删除账户后为您恢复数据，请慎重决定删除账户信息。

如您确实希望删除账户，可以在个人主页找到删除账户的按钮，点击后会提示您输入密码以确认删除操作，确认后将会立即删除您的账户及数据。

删除账户的执行程序如下：
1. 通过密码确认您的身份，以确保您是账户的拥有者。
2. 在系统中作出标识临时，确保备份系统不会再次将您的信息意外恢复，并在备份完成后清除临时标识。
3. 清除您的账户信息，包括但不限于账户标识、密码、邮箱地址、手机号码、头像、显示名称等。
4. 将您创建的比赛记录数据等信息标记为匿名信息，与您的账户脱钩，并确保全体用户无法查看这些数据。
5. 将您的信息从与他人协作的比赛记录数据中标记为匿名信息，确保全体用户无法查看这些数据。

## 未成年人保护
正赛重视对未成年人个人信息的保护，若用户是 18 周岁以下的未成年人，在使用正赛的服务前，应事先取得用户的家长或法定监护人的同意。

--- 
如有疑问，请发送邮件到 djw7569@gmail.com 与我们取得联系。

`
}

