import { Button } from '@/shadcn/components/ui/button'
import { Form, FormField } from '@/shadcn/components/ui/form'
import * as z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from 'react-hook-form'
import { upload } from '@/utils/laf-db'
import { FileInput, FileUploader } from '@/shadcn/components/extension/file-uploader'
import { Avatar, AvatarFallback, AvatarImage } from '@/shadcn/components/ui/avatar'
import { CheckIcon, XIcon } from 'lucide-react'

const dropzone = {
  // multiple: false,
  // maxFiles: 3,
  maxSize: 4 * 1024 * 1024,
}

const formSchema = z.object({
  files: z.instanceof(File).optional(),
})

const previewImage = (file) => {
  return (
    <Avatar className={"w-24 h-24"}>
      <AvatarImage src={URL.createObjectURL(file)} alt="Avatar" className="rounded-full object-cover" />
      <AvatarFallback >{file.name}</AvatarFallback>
    </Avatar>
  )
}

export const ImageUploaderForm = ({ placeholder, uploadedCallback }) => {
  const form = useForm({ resolver: zodResolver(formSchema), defaultValues: { files: [] } })
  // let watch = form.watch()

  const onSubmit = async () => {
    // console.log(form.getValues())
    let image = form.getValues()?.files?.[0]
    if (image) {
      let result = await upload("/upload/image", image)
      uploadedCallback(result, image)
    }
  }

  return (
    <Form {...form}>
      <form className="w-full">
        <FormField
          control={form.control}
          name="files"
          render={({ field: { value, onChange, ...fieldProps } }) => (
            <div className='relative w-fit mx-auto flex flex-col items-center'>
              <FileUploader
                value={value}
                onValueChange={(e) => {
                  onChange(e)
                }}
                dropzoneOptions={dropzone}
                className="mx-auto w-28 h-28"
              >
                <FileInput className="cursor-pointer border border-dashed w-28 h-28 rounded-full flex justify-center items-center">
                  {value?.[0] ? previewImage(value[0]) : placeholder}
                </FileInput>
              </FileUploader>

              <div className="flex gap-4 mt-2">
                <Button disabled={!value?.[0]} size="icon" variant="outline" className="rounded-full" onClick={(e) => {
                  e.preventDefault()
                  form.setValue("files", [])
                }}>
                  <XIcon />
                </Button>
                <Button disabled={!value?.[0]} size="icon" className="rounded-full" onClick={(e) => {
                  e.preventDefault()
                  onSubmit()
                }}>
                  <CheckIcon />
                </Button>
              </div>
            </div>
          )}
        />
      </form>
    </Form>
  )
}