import React, { useState } from 'react'
import { Minus, Dices, Armchair, User } from 'lucide-react'
import { PlayerSmallCard } from '@/components/player-card'
import { Button } from '@/shadcn/components/ui/button'
import { Modal } from '@/shadcn/components/_modal'
import { post } from '@/utils/laf-db'
import { PlayerAvatar } from '@/shadcn/components/_player-avatar'
import { Confirm } from '@/shadcn/components/_confirm'
import { PlayersPicker } from '@/components/players-picker'
import { cn } from '@/shadcn/utils'
import { useCurrentUser } from '@/hooks/use-session'

export const Attendees = ({ event, mutate, matching }) => {
  let isPreparing = event?.stage === "1-REGISTRATION"
  const [selectedIds, setSelectedIds] = useState(event?.attendeeIds || [])
  const { data: account } = useCurrentUser()

  if (!isPreparing && event?.attendees) {
    return (
      <div className="flex flex-col p-4">
        <div className='mb-4'>
          Total {event.attendees.length} Attendees
        </div>
        <div className="flex flex-wrap ">
          {event.attendees.map(player => {
            return <PlayerAvatar key={player.id} player={player} className='w-8 h-8 bg-primary-foreground -mr-2 mb-2' />
          })}
        </div>
      </div>
    )
  }

  const save = async () => {
    let url = `/events/set`
    await post(url, { eventId: event.id, attendeeIds: selectedIds })
    mutate()
  }

  const remove = async (i) => {
    let url = `/events/delete-attendees`
    await post(url, { eventId: event.id, attendeeIds: [i.id] })
    await mutate()
    setSelectedIds(selectedIds.filter(id => id !== i.id))
  }

  return (
    <div className='h-full flex flex-col'>
      <div className='flex flex-col justify-between p-4' >
        <div className=''>
          Total {event?.attendees?.length} Attendees
        </div>
        {matching && isPreparing && (
          <div className='flex gap-2 mt-2 w-full'>
            <Confirm
              confirmText="重置选手次序"
              title="确定重置选手次序吗？"
              description="重置选手次序会随机为选手分配新的编号，可使部分赛制的人为因素减少。"
              triggerButton={(
                <Button size="sm" variant="outline" >
                  <Dices className='w-4 h-4 mr-1' />
                  <span>重置顺序</span>
                </Button>
              )}
              confirmAction={async () => {
                if (event?.randomAttendees) {
                  await event.randomAttendees()
                  await mutate()
                }
              }}
            >
            </Confirm>
            <Modal
              title={"Register attendees from your player pool"}
              triggerButton={
                <Button size="sm" variant="outline" >
                  <Armchair className='w-4 h-4 mr-1' />
                  <span>调整选手</span>
                </Button>
              }
              render={({ close }) => {
                return (
                  <>
                    <PlayersPicker
                      value={selectedIds}
                      onChange={setSelectedIds}
                      option={{ cleanable: true }}
                    />
                    <Button onClick={async () => {
                      await save()
                      close()
                    }}>Save</Button>
                  </>
                )
              }}
            />
          </div>
        )}
      </div>

      <div className='overflow-scroll px-4 pb-4'>
        {event?.attendees?.map?.((i) => {
          let isAccountPlayer = i.asAccount === true && i.owner === account?.id
          let showRemoveButton = isPreparing && matching && isAccountPlayer === false

          return (
            <PlayerSmallCard key={i.id} player={i}
              className={"group relative hover:bg-secondary cursor-pointer"}
              appendRender={
                <div className={cn("space-x-1 absolute right-1 z-10")}>
                  {showRemoveButton && (
                    <Confirm
                      confirmText="确定，移除该选手"
                      title="确定要移除这位选手吗？"
                      description="如该选手为自主报名，您将无法再为其报名，除非选手再次申请。"
                      triggerButton={(
                        <Button variant="ring" size="icon" className={cn("rounded-full w-6 h-6 opacity-0 group-hover:opacity-100")}>
                          <Minus className="h-4 w-4" />
                        </Button>
                      )}
                      confirmAction={() => remove(i)}
                    >
                    </Confirm>
                  )}
                  {isAccountPlayer && (
                    <div>
                      <span className="block group-hover:hidden"> <User className='w-4 h-4' /> </span>
                      <Button variant="outline" size="sm"
                        className="hidden group-hover:block"
                        onClick={() => remove(i)}>
                        取消报名
                      </Button>
                    </div>
                  )}
                </div>
              }
            />
          )
        })}
      </div>
    </div>
  )
}

