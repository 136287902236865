import React from 'react'
import { Card, CardDescription, CardFooter, CardHeader, CardTitle, } from './ui/card'
import { FileQuestion } from 'lucide-react'
import { cn } from '../utils'

export function ContentUnavailable({ title, description, className, action, icon }) {
  let defaultTitle = "暂无内容"
  let defaultDescription = "Content Unavailable"
  return (
    <Card className={cn("flex justify-center flex-col", className)}>
      <CardHeader className="flex items-center flex-col">
        <div className='mb-2'>
          {icon ? icon : (
            <FileQuestion className='w-12 h-12 text-muted-foreground mb-2' />
          )}
        </div>

        <CardTitle>{title || defaultTitle}</CardTitle>
        <CardDescription>{description || defaultDescription}</CardDescription>
      </CardHeader>
      <CardFooter className="flex items-center flex-col">
        {action}
      </CardFooter>
    </Card>
  )
}


