import { Button } from '@/shadcn/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/shadcn/components/ui/form'
import { Input } from '@/shadcn/components/ui/input'
import * as z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from 'react-hook-form'
import { useToggle } from 'react-use'
// import { playerThemeColors } from '@/pages/players/form-editor'
// import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '@/shadcn/components/ui/select'

const formSchema = z.object({
  displayName: z.string(),
  // color: z.string(),
})

export const DisplayNameForm = ({ account, mutate, close }) => {
  const [isLoading, toggleLoading] = useToggle(false)

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      displayName: account?.displayName || "",
      // color: player?.color || playerThemeColors["Zinc"],
    },
  })

  const save = async () => {
    toggleLoading(true)
    let result = await form.trigger()
    if (!result) { return }
    let values = form.getValues()
    await account.updateUserDisplayField({ displayName: values.displayName })
    toggleLoading(false)
  }

  return (
    <Form {...form} >
      <form className="space-y-4">
        <FormField
          control={form.control}
          name="displayName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Display Name</FormLabel>
              <FormControl>
                <Input placeholder="" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {/* <FormField
          control={form.control}
          name="color"
          render={({ field }) => {
            return (
              <FormItem className="flex flex-col relative">
                <FormLabel>Color</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Choose player theme color" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent className="h-64" >
                    {Object.entries(playerThemeColors).map(([k, v]) => {
                      return <SelectItem key={k} value={k} className="">
                        <div className='flex items-center space-x-2'>
                          <div style={{ background: `linear-gradient(to bottom, ${v.light}, ${v.dark})` }}
                            className={`h-3 w-3 rounded-full`}
                          />
                          <div>{k}</div>
                        </div>
                      </SelectItem>
                    })}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )
          }}
        /> */}
      </form>
      <Button
        variant="secondary"
        className="w-full mt-4 lg:mt-0"
        disabled={isLoading}
        onClick={async () => {
          await save()
          await mutate()
          close()
        }}
      >
        Save
      </Button>
    </Form >
  )
}