import { Button } from '@/shadcn/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/shadcn/components/ui/form'
import * as z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from 'react-hook-form'
import { PasswordInput } from '@/shadcn/components/_password-input'
import { Separator } from '@/shadcn/components/ui/separator'
import { toast } from 'sonner'

const formSchema = z.object({
  oldPassword: z.string(),
  password: z.string(),
  confirmPassword: z.string(),
}).refine((data) => data.password === data.confirmPassword, {
  message: "Passwords don't match",
  path: ["confirmPassword"],
})

export const ResetPasswordForm = ({ account, mutate, close }) => {
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
  })

  const save = async () => {
    let result = await form.trigger()
    if (!result) { return }
    let { oldPassword, password } = form.getValues()
    let { code, message } = await account.resetPasswordByPassword(oldPassword, password)
    if (code === 1) {
      await mutate()
      close()
      toast("修改密码成功", { closeButton: true })
    } else {
      toast.message("修改密码失败", { description: message })
    }
  }

  return (
    <Form {...form} >
      <form className="space-y-4">
        <FormField
          control={form.control}
          name="oldPassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel>当前密码</FormLabel>
              <FormControl>
                <PasswordInput placeholder="请输入当前密码" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>新密码</FormLabel>
              <FormControl>
                <PasswordInput placeholder="请输入新密码" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="confirmPassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel>确认密码</FormLabel>
              <FormControl>
                <PasswordInput placeholder="再次输入新密码" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
      <Separator className="my-4" />
      <Button variant="secondary" className="w-full" onClick={save}>
        Reset Password
      </Button>
    </Form >
  )
}