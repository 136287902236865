// 获取到用户账户信息之后，整合成一个实例，方便前端取数据

import { post } from "@/utils/laf-db"

const defaultAvatar = ""
export class UserAccount {
  constructor(json) {
    // 基本数据
    // this.user = json.user
    // this.session = json.session
    // this.userData = json.userData
    // this.attributes = json.attributes


    // 从基本数据里翻出来的数据，方便前端取用

    this.id = json.id
    this.identifier = json.identifier
    this.displayName = json.displayName || "新用户"  // 显示名称，对外显示的名字
    this.bindAppleId = json.bindAppleId || false // 是否绑定 Apple ID
    this.email = json.email || ""
    this.emailVerified = json.emailVerified || false
    this.phone = json.phone || ""
    this.avatar = json.avatar || defaultAvatar // 头像
    this.avatarFallback = this.displayName.slice(0, 1) // 无头像时的候补文字
  }


  // 更新字段，要先删除本地缓存，否则容易导致界面显示不准确
  updateUserProfile = async (key, value) => {
    localStorage.removeItem("account_data")
    return await post('/auth/update-field', {
      fieldKey: key,
      fieldValue: value,
    })
  }

  // 更新字段，要先删除本地缓存，否则容易导致界面显示不准确
  updateUserDisplayField = async (updater) => {
    localStorage.removeItem("account_data")
    return await post('/auth/update-display-field', updater)
  }

  // 核验邮箱，认证邮箱状态为“已验证”
  verifyEmail = async (code) => {
    localStorage.removeItem("account_data")
    return await post('/auth/verify-email', {
      code: code,
    })
  }

  // 通过密码重置密码
  resetPasswordByPassword = async (oldPassword, newPassword) => {
    localStorage.removeItem("account_data")
    return await post('/auth/reset-password-by-password', {
      oldPassword,
      newPassword,
    })
  }
}