'use client'
import React from 'react'
import * as z from "zod"
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/shadcn/components/ui/form'
import { Button } from '@/shadcn/components/ui/button'
import { useForm } from 'react-hook-form'
import { zodResolver } from "@hookform/resolvers/zod"
import { Textarea } from "@/shadcn/components/ui/textarea"
import { post } from '@/utils/laf-db'
import { DialogFooter } from '@/shadcn/components/ui/dialog'


const formSchema = z.object({
  playerNames: z.string().min(1, {
    message: "Enter at least one player name.",
  }),
})


export function RegisterPlayers({ mutate, close }) {
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      playerNames: "",
    },
  })

  const formSubmit = async (values) => {
    let names = values.playerNames.split('\n').filter(i => i !== "")
    let players = names.map(name => ({ name, color: "Zinc" }))
    await post("/players/add", { players })
    form.reset()
    mutate()
    close()
  }


  return (
    <Form {...form} >
      <form onSubmit={form.handleSubmit(formSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="playerNames"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Player Names</FormLabel>
              <FormDescription>Enter multiple names and separate them by line breaks.</FormDescription>
              <FormControl>
                <Textarea className="h-64" placeholder="" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <DialogFooter>
          <Button type="submit">Append</Button>
        </DialogFooter>
      </form>
    </Form>
  )
}

