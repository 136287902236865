import { post } from "@/utils/laf-db"
import { Player } from "./player"


export class Community {

  constructor(json) {
    if (json === undefined) return

    this.dbModel = json
    this.owner = json.owner
    this.organizer = json.organizer
    this.id = json._id
    this.name = json?.name || '<Data Error, No Name>'
    this.members = json?.members.map(i => new Player(i)) || []
  }

  setStage = async (stage) => {
    let body = { id: this.id, stage }
    return await post('/community/set', body)
  }
}
